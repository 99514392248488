import { getAliasQueryParam } from '@/utils/browser';

export const SHOPIFY_POST_PURCHASE_STG = `https://sdk.loomi-stg.xyz/post-purchase/index.html`;
export const SHOPIFY_POST_PURCHASE_PROD = `https://sdk.loomi-prod.xyz/post-purchase/index.html`;

const BYPASS_PROXY_URLS = [
  `https://storage.googleapis.com/loomi-production-sdk/vsly-shopify-pp-snapshot.html`,
  `https://sdk.loomi-prod.xyz/vsly-shopify-pp-snapshot.html`,
  SHOPIFY_POST_PURCHASE_PROD,
  SHOPIFY_POST_PURCHASE_STG,
];

export enum Env {
  LOCAL = `local`,
  STAGING = `staging`,
  PRODUCTION = `production`,
}

export class Routes {
  private env: string = Env.LOCAL;

  private proxyEnv: string = Env.LOCAL;

  private getPublicSdkDomain() {
    if (Env.PRODUCTION === this.env) {
      return `https://sdk.loomi-prod.xyz/`;
    }
    return `https://sdk.loomi-stg.xyz/`;
  }

  private getEnvPrefix() {
    if (Env.STAGING === this.env) {
      return `/stage/`;
    }
    if (Env.PRODUCTION === this.env) {
      return `/`;
    }
    if (Env.LOCAL === this.env) {
      return `/local/`;
    }

    return `/local/`;
  }

  private getProxyEnvPrefix() {
    if (Env.STAGING === this.proxyEnv) {
      return `/stage/`;
    }
    if (Env.PRODUCTION === this.proxyEnv) {
      return `/`;
    }
    if (Env.LOCAL === this.proxyEnv) {
      return `/local/`;
    }

    return `/local/`;
  }

  withEnv(env: Env, proxyEnv: Env) {
    this.env = env;
    this.proxyEnv = proxyEnv;
  }

  getEnv(): number {
    return (this.env as Env) === Env.PRODUCTION ? 2 : 1;
  }

  getEnvEnum(): Env {
    return this.env as Env;
  }

  logout() {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/auth/logout`;
  }

  userContext() {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/user/context`;
  }

  get downloadPrivateKey() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/rawdata-export/key`;
  }

  get toggleActivityStream() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/rawdata-export/toggle`;
  }

  get getActivityStreamStatus() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/rawdata-export/is-on`;
  }

  userDetails() {
    return `${this.getEnvPrefix()}lmiapi/editor/web/v1/user/details`;
  }

  updateUserDetails() {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/user/personal-info`;
  }

  get persistFbToken() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/public/facebook/auth`;
  }

  get persistInstagramToken() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/public/instagram/auth`;
  }

  get encryptAlias() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/encrypt`;
  }

  get integrations() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/integrations`;
  }

  get instagramImages() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/instagram/images`;
  }

  get listFbAdAccounts() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/fb/adaccounts`;
  }

  get lisInstagramAccounts() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/instagram/accounts`;
  }

  get listFbCampaigns() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/fb/campaigns`;
  }

  get fbVerify() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/fb/validate`;
  }

  get loadMoreFbCampaigns() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/fb/campaigns/next`;
  }

  get loadMoreFbAdSets() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/fb/adsets/next`;
  }

  get loadMoreFbAds() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/fb/ads/next`;
  }

  get selectAdAccount() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/fb/adaccount`;
  }

  get listFBImages() {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/fb/images`;
  }

  get storeSummary() {
    return `${this.getEnvPrefix()}lmiapi/analytics/statistics/v1/store/summary`;
  }

  get experienceSummary() {
    return `${this.getEnvPrefix()}lmiapi/analytics/statistics/v1/store/summary/experiences`;
  }

  get experiencesNames() {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/experience/names`;
  }

  get saveExperienceSessionsType() {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/sessions-type`;
  }

  exportStats(expId: string): string {
    return `${this.getEnvPrefix()}lmiapi/v1/experience/${expId}/stats`;
  }

  quickPreview(website: string): string {
    if (BYPASS_PROXY_URLS.includes(website)) {
      return website;
    }
    return `${this.getProxyEnvPrefix()}lmiproxy/${encodeURIComponent(
      btoa(unescape(encodeURIComponent(website))),
    )}?init-req=true&vslyalias=${getAliasQueryParam()}`;
  }

  cleanQuickPreview(website: string): string {
    if (BYPASS_PROXY_URLS.includes(website)) {
      return website;
    }
    return `${this.getProxyEnvPrefix()}lmiproxy/clean/${encodeURIComponent(
      btoa(unescape(encodeURIComponent(website))),
    )}?init-req=true&vslyalias=${getAliasQueryParam()}`;
  }

  demoVideoLegend(): string {
    return `${this.getEnvPrefix()}lmiapi/editor/web/v1/public/video/demo/metadata`;
  }

  upsertExperience(): string {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/experience`;
  }

  get clearCache(): string {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/clear-cache`;
  }

  duplicateExperience(): string {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/duplicate/experience`;
  }

  deleteExperience(id: string): string {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/experience/${id}`;
  }

  findExperience(id: string): string {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/experience/${id}`;
  }

  listExperiences(): string {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/experience/list`;
  }

  publishExperience(): string {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/experience/publish`;
  }

  archiveExperience(id: string): string {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/experience/${id}/archive`;
  }

  pauseExperience(id: string): string {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/experience/${id}/pause`;
  }

  get generatePreviewFromExperience(): string {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/experience/preview`;
  }

  get generatePermanentPreviewFromExperience(): string {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/experience/permanent-preview`;
  }

  sharePreview(): string {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/experience/preview-share`;
  }

  listEntireCatalog(): string {
    return `${this.getEnvPrefix()}lmiapi/catalog/web/v1/list`;
  }

  listCatalog(): string {
    return `${this.getEnvPrefix()}lmiapi/catalog/web/v1/list/paginate`;
  }

  get submitCatalogSync(): string {
    return `${this.getEnvPrefix()}lmiapi/catalog/web/v1/catalog-sync/submit`;
  }

  get getCatalogSyncStatus(): string {
    return `${this.getEnvPrefix()}lmiapi/catalog/web/v1/catalog-sync`;
  }

  get awaitCatalogSyncDone(): string {
    return `${this.getEnvPrefix()}lmiapi/catalog/web/v1/catalog-sync/await`;
  }

  listRecommendationProducts(): string {
    return `${this.getEnvPrefix()}lmiapi/catalog/web/v1/recommendation/products/list`;
  }

  recommendProducts(query: string): string {
    return `${this.getPublicSdkDomain()}api/recommendations/web/public/recommend?q=${query}`;
  }

  dailySummaryResults(): string {
    return `${this.getEnvPrefix()}lmiapi/analytics/statistics/v1/experiences/summary/daily`;
  }

  summaryResults(): string {
    return `${this.getEnvPrefix()}lmiapi/analytics/statistics/v1/experiences/summary`;
  }

  get exportOrders(): string {
    return `${this.getEnvPrefix()}lmiapi/analytics/statistics/v1/experiences/summary/orders`;
  }

  experienceVersions(): string {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/experience/versions`;
  }

  signup(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/public/signup`;
  }

  subscriptionStatus(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/public/subscription/status`;
  }

  awaitActiveSubscription(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/public/subscription/await-active`;
  }

  listUsers(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/users`;
  }

  initShop(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/public/shops/create`;
  }

  createUser(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/accounts/create`;
  }

  deactivateUser(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/users/deactivate`;
  }

  updateUserRole(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/users/role`;
  }

  verifyLogin(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/public/auth/verify`;
  }

  listenForVerification(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/public/auth/verify/listen`;
  }

  resendSignupEmail(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/public/email/resend`;
  }

  login(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/public/auth/login`;
  }

  fb() {
    this.getEnv();
    let envPrefix = `/stage/`;
    if (window?.location?.origin === `https://visually.io/`) {
      envPrefix = ``;
    }
    return `${envPrefix}lmiapi/accounts/web/v1/public/fb/status`;
  }

  myAccounts(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/my/accounts`;
  }

  selectAccount(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/accounts/select`;
  }

  listAllApps(): string {
    return `${this.getEnvPrefix()}lmiapi/apps-catalog/web/v1/apps/list`;
  }

  get overrideStoreStyle(): string {
    return `${this.getEnvPrefix()}lmiapi/apps-catalog/web/v1/styles/override`;
  }

  getChange(): string {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/change`;
  }

  listStockImages(): string {
    return `${this.getEnvPrefix()}lmiapi/media/web/v1/stock/query`;
  }

  get imageAiGen(): string {
    return `${this.getEnvPrefix()}lmiapi/media/web/v1/ai/gen`;
  }

  trackStockImageDownload(): string {
    return `${this.getEnvPrefix()}lmiapi/media/web/v1/stock/download`;
  }

  listGalleryImages(): string {
    return `${this.getEnvPrefix()}lmiapi/media/web/v1/gallery/list`;
  }

  softDelImage(): string {
    return `${this.getEnvPrefix()}lmiapi/media/web/v1/gallery/del`;
  }

  uploadMediaToGallery(): string {
    return `${this.getEnvPrefix()}lmiapi/media/web/v1/gallery/upload`;
  }

  reSubscribe(alias: string): string {
    return `${this.getEnvPrefix()}lmiapi/shopify-integration/web/v1/re-subscribe/${alias}`;
  }

  removeSDK(themeId?: string): string {
    if (themeId) {
      return `${this.getEnvPrefix()}lmiapi/shopify-integration/web/v1/clean-sdk?themeId=${themeId}`;
    }
    return `${this.getEnvPrefix()}lmiapi/shopify-integration/web/v1/clean-sdk`;
  }

  injectSDK(themeId?: string): string {
    if (themeId) {
      return `${this.getEnvPrefix()}lmiapi/shopify-integration/web/v1/inject-sdk?themeId=${themeId}`;
    }
    return `${this.getEnvPrefix()}lmiapi/shopify-integration/web/v1/inject-sdk`;
  }

  getSubscription(alias: string): string {
    return `${this.getEnvPrefix()}lmiapi/shopify-integration/web/v1/subscription?alias=${alias}`;
  }

  listThemes(alias: string): string {
    return `${this.getEnvPrefix()}lmiapi/shopify-integration/web/v1/themes?alias=${alias}`;
  }

  get themesStatus(): string {
    return `${this.getEnvPrefix()}lmiapi/shopify-integration/web/v1/themes-status`;
  }

  isPostPurchaseEnabled(alias: string): string {
    return `${this.getEnvPrefix()}lmiapi/shopify-integration/web/v1/is-post-purchase-enabled?alias=${alias}`;
  }

  isABlockEnabled(alias: string, themeId: number): string {
    return `${this.getEnvPrefix()}lmiapi/shopify-integration/web/v1/is-app-block-enabled?alias=${alias}${
      themeId > 0 ? `&themeId=${themeId}` : ``
    }`;
  }

  get getPostPurchase(): string {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/current-post-purchase`;
  }

  reviewsEndpoint(): string {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/settings/reviews`;
  }

  get getTextAlt(): string {
    return `${this.getEnvPrefix()}lmiapi/store-settings/web/v1/alternative-text`;
  }

  trackUserEvent(): string {
    return `${this.getEnvPrefix()}lmiapi/editor/web/v1/user/stats/track`;
  }

  trackStoreEvent(): string {
    return `${this.getEnvPrefix()}lmiapi/editor/web/v1/store/stats/track`;
  }

  getUserStats(): string {
    return `${this.getEnvPrefix()}lmiapi/editor/web/v1/user/stats/list`;
  }

  getStoreStats(): string {
    return `${this.getEnvPrefix()}lmiapi/editor/web/v1/store/stats/list`;
  }

  getInspirations(): string {
    return `${this.getEnvPrefix()}lmiapi/editor/web/v1/inspirations/list`;
  }

  recommendInspirations(): string {
    return `${this.getEnvPrefix()}lmiapi/editor/web/v1/inspirations/recommend`;
  }

  markInspirationAsSeen(): string {
    return `${this.getEnvPrefix()}lmiapi/editor/web/v1/inspirations/markAsSeen`;
  }

  getInspirationStatus(): string {
    return `${this.getEnvPrefix()}lmiapi/editor/web/v1/inspirations/status`;
  }

  contactMe(): string {
    return `${this.getEnvPrefix()}lmiapi/editor/web/v1/public/misc/contactMe`;
  }

  get paymentDue(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v1/payment`;
  }

  saveStyles(): string {
    return `${this.getEnvPrefix()}lmiapi/apps-catalog/web/v1/styles`;
  }

  deleteStyle(): string {
    return `${this.getEnvPrefix()}lmiapi/apps-catalog/web/v1/styles/delete`;
  }

  autoSaveStyles(): string {
    return `${this.getEnvPrefix()}lmiapi/apps-catalog/web/v1/styles/autosave`;
  }

  listStyles(): string {
    return `${this.getEnvPrefix()}lmiapi/apps-catalog/web/v1/styles/list`;
  }

  listAccounts(): string {
    return `${this.getEnvPrefix()}lmiapi/accounts/web/v2/my/accounts`;
  }

  get audiences() {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/audiences`;
  }

  get audiencesList() {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/audiences/list`;
  }

  get deleteAudiences() {
    return `${this.getEnvPrefix()}lmiapi/experience/web/v1/audiences/delete`;
  }
}

export const routes = new Routes();
