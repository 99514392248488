import * as React from 'react';
import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { EditorContext } from '@/features/editor/context/editor-context';
import { centered, useSharedElement } from '@/components/use-shared-element';
import { SharedElementOverlay } from '@/components/shared-element-overlay';
import { InputModal } from '@/components/input-modal';
import { DeviceType } from '@/utils/definitions';
import { UNNAMED_EXPERIENCE } from '@/webapi/use-experience-api';
import { DescriptionTooltip } from '@/components/tooltip';
import { AccountContext } from '@/features/account-context';
import { StoreStatsKind } from '@/webapi/use-store-stats';

export function DescriptionEditButton() {
  const { isStoreGoalReached } = useContext(AccountContext);
  const {
    experienceState: { currentExperience, setExperienceName },
    devicePreview,
  } = useContext(EditorContext);

  const {
    editorState: { device },
  } = devicePreview;

  const {
    props: renameProps,
    show: showRenameModal,
    hide: hideRenameModal,
    fromRef: renameRef,
  } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `transparent`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    undefined,
    () => centered(43, 50),
  );

  const onRename = (description: string) => {
    setExperienceName(``, description || null);
    hideRenameModal();
  };

  const descText = useMemo(() => {
    if (currentExperience.description) {
      return currentExperience.description;
    }
    if (!isStoreGoalReached(StoreStatsKind.HAS_SAVED_ONE_EXPERIENCE)) {
      return `Once done, decide how to allocate traffic for A/B testing`;
    }
    return `+ Add a description to the experience name`;
  }, [currentExperience.description]);

  if (
    currentExperience.name === UNNAMED_EXPERIENCE &&
    isStoreGoalReached(StoreStatsKind.HAS_SAVED_ONE_EXPERIENCE)
  ) {
    return null;
  }

  return (
    <>
      <DescriptionTooltip
        id={currentExperience.id}
        description={currentExperience.description}
      />
      <SharedElementOverlay {...renameProps}>
        <InputModal
          title="Edit description"
          description={
            <div>
              Change the description to clarify the name of the
              <br /> experience for later reference
            </div>
          }
          yesCaption="Update"
          noCaption="Discard"
          textArea
          placeholder={currentExperience?.description}
          initialValue={currentExperience?.description}
          onConfirm={onRename}
          onDiscard={hideRenameModal}
        />
      </SharedElementOverlay>

      <Title ref={renameRef} onClick={showRenameModal} device={device}>
        <span data-tip data-for={`desc${currentExperience.id}`}>
          {descText}
        </span>
      </Title>
    </>
  );
}

const Title = styled.span<{ device: DeviceType }>`
  && {
    margin-top: 2rem;
    cursor: context-menu;
    color: #93989b;
    font-size: 1.4rem;

    display: block;
    width: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `100%` : `90%`};
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    font-weight: 400;

    :hover {
      opacity: 0.7;
    }
  }
`;
