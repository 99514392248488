/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import styled from 'styled-components';
import { DefaultTypography } from '@/components/typography';
import { DeviceType } from '@/utils/definitions';

export interface CheckboxProps {
  initialValue?: boolean;
  caption?: any;
  onSelection?: (isSelected: boolean) => void;
  device?: DeviceType;
  size?: 'small';
  disableCaptionToggle?: boolean;
  blueCheckmark?: boolean;
}

export function Checkbox({
  initialValue,
  caption,
  onSelection,
  blueCheckmark,
  device,
  size,
  disableCaptionToggle,
}: CheckboxProps) {
  const [selected, setSelected] = useState(!!initialValue);

  const onClick = () => {
    onSelection && onSelection(!selected);
    setSelected(!selected);
  };

  return (
    <Wrapper className="checkbox_wrapper" device={device}>
      {blueCheckmark && selected ? (
        <BlueCheckmark />
      ) : (
        <BoxWrapper className="box_wrapper" size={size} onClick={onClick}>
          <BoxSelected selected={selected} />
        </BoxWrapper>
      )}
      <Caption
        onClick={() => !disableCaptionToggle && onClick()}
        device={device}
        selected={selected}
        caption={caption}
        size={size}
      >
        {caption}
      </Caption>
    </Wrapper>
  );
}

const BlueCheckmark = () => (
  <svg
    width="17px"
    height="16px"
    viewBox="0 0 17 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Group</title>
    <g id="Var-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard-Copy-81" transform="translate(-705, -420)">
        <g id="Group-2" transform="translate(705, 378)">
          <g id="Group" transform="translate(0, 42)">
            <rect
              id="Rectangle-Copy"
              fill="#3B47FF"
              x="0"
              y="0"
              width="17"
              height="16"
              rx="4"
            />
            <g
              id="Path-33"
              transform="translate(5, 5.5)"
              stroke="#FFFFFF"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.6896"
            >
              <g id="Path-32">
                <polyline
                  id="Path-31"
                  points="0 2.61383867 2.26140868 5 7 -2.6340385e-16"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const Wrapper = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: grid;
  grid-column-gap: ${({ device }: { device: DeviceType }) =>
    device === DeviceType.Desktop ? `1rem` : `2rem`};
  align-items: center;
  justify-content: stretch;
  grid-template-columns: ${({ caption }: { caption?: string }) =>
    caption ? `2.5rem` : `2.5rem 1fr`};
`;

const Caption = styled(DefaultTypography)`
  && {
    display: ${({ caption }: { caption?: string }) =>
      caption ? `block` : `none`};
    color: ${({ selected }: { selected: boolean }) =>
      selected ? `#5d606c` : `#737989`};
    font-weight: 500;
    font-size: ${({ device, size }: { device: DeviceType; size: 'small' }) =>
      size === `small`
        ? `1.4rem`
        : device === DeviceType.Desktop
        ? `1.6rem`
        : `1.8rem`};
  }
`;

const BoxWrapper = styled.div`
  height: ${(p: { size?: 'small' }) =>
    p?.size === `small` ? `1.5rem` : `2.5rem`};
  width: ${(p: { size?: 'small' }) =>
    p?.size === `small` ? `1.5rem` : `2.5rem`};
  background: #efefef;
  border: 1px solid #979797;
  border-radius: 4px;
  padding: 0.3rem;
`;

const BoxSelected = styled.span`
  pointer-events: none;
  height: 100%;
  width: 100%;
  display: block;
  opacity: ${({ selected }: { selected: boolean }) => (selected ? `1` : `0`)};
  transform: ${({ selected }: { selected: boolean }) =>
    selected ? `scale(1,1)` : `scale(0,0)`};
  background: #3451fa;
  border-radius: 3px;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
`;
