import React, { useContext, useMemo, useState } from 'react';
import { GradientModal } from '@/components/gradient-modal';
import { centered } from '@/components/use-shared-element';
import { Footer } from '@/features/editor/widgets/shared/modals/commons';
import { QBItemSelection } from '@/components/query-builder/models';
import { QueryBuilder } from '@/components/query-builder';
import { usePlacementQueryBuilder } from '@/features/editor/widgets/shared/modals/use-placement-query-builder';
import { EditorContext } from '@/features/editor/context/editor-context';
import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';
import { AccountContext } from '@/features/account-context';

export interface RedirectSourceUrlModalProps {
  newChange: EditorDeclarativeBlock;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  onSave: (rules: Array<QBItemSelection>) => void;
  fromRef: React.MutableRefObject<HTMLElement>;
}

export function RedirectSourceUrlModal({
  newChange,
  fromRef,
  isVisible,
  onSave,
  setIsVisible,
}: RedirectSourceUrlModalProps) {
  const {
    account: {
      store: { domain },
    },
  } = useContext(AccountContext);
  const {
    resources,
    experienceState: { currentExperience },
  } = useContext(EditorContext);

  const modalCfg = getModalConfig(fromRef);
  const { pageUrl } = usePlacementQueryBuilder(
    resources,
    currentExperience.experienceCreationVersion,
  );
  const defaultRule = useMemo<Array<QBItemSelection>>(
    () => [
      {
        qbProps: pageUrl,
        values: [
          {
            op: `includes`,
            value: domain,
          },
        ],
      },
    ],
    [domain],
  );
  const [rules, setRules] = useState(
    newChange?.pageRedirectOpts?.sourceUrlProps || defaultRule,
  );

  const onSaveClicked = () => {
    onSave(rules);
    setIsVisible(false);
  };

  const onQueryBuilderChange = (rules: Array<QBItemSelection>) => {
    setRules(rules);
  };

  return (
    <GradientModal
      {...modalCfg}
      isVisible={isVisible}
      onClose={() => setIsVisible(false)}
      header="Please select the source url logic"
      footer={<Footer onClick={onSaveClicked} text="Save" />}
    >
      <QueryBuilder
        onChange={onQueryBuilderChange}
        initialProps={[pageUrl]}
        initialState={rules}
      />
    </GradientModal>
  );
}

function getModalConfig(fromRef: React.MutableRefObject<any>) {
  const targetPosAndSize = centered(60, 90);

  return {
    targetPosAndSize,
    closeTop: `0.5rem`,
    closeRight: `2.5rem`,
    fromStyle: {
      borderRadius: `5rem`,
      backgroundColor: `#DEDEDE`,
      padding: `2rem 0 0 0`,
    },
    toStyle: {
      borderRadius: `1rem`,
      backgroundColor: `#FFFFFF`,
      padding: `2rem 0 0 0`,
    },
    showBackdrop: true,
    fromRef,
    overflowTop: { heightInRem: 8 },
    overflowBottom: { heightInRem: 8, turnPointInPercent: 30 },
  };
}
