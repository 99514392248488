import produce from 'immer';
import { useContext } from 'react';
import { StyleTemplate } from '@/features/editor/widgets/custom-widget/style-templating/models';
import { CustomWidgetContextProps } from '@/features/editor/widgets/custom-widget/shared/context';
import {
  Customization,
  CustomizationSpec,
} from '@/webapi/use-widget-catalog-api';
import { AccountContext } from '@/features/account-context';

export type SelectOption = {
  value: StyleTemplate;
  label: string;
};

export function getCurrProps(context: CustomWidgetContextProps) {
  return context.currentSchema;
}

export function newId() {
  return Math.random().toString(36).substring(2, 16);
}

export function findInitialSelection(
  options: Array<{
    label: string;
    value: StyleTemplate;
  }>,
  widget: CustomWidgetContextProps,
) {
  return (
    options?.find?.(
      (o) =>
        o.value.id === widget.currentWidget.blueprint.schema.styleTemplateId,
    )?.value || options?.[0]?.value
  );
}

function isBGImgOrVid(spec) {
  return (
    spec.type === `BACKGROUND` && [`video`, `image`].includes(spec.values.mode)
  );
}

function isLinkOrTextOrMargin(spec) {
  return [`LINK`, `TEXT_EDIT`, `MARGIN`].includes(spec.type);
}

function hasDeskVal(currentSpec: Record<string, any>, spec) {
  return (
    currentSpec.value?.desktop?.value && spec.values?.value?.desktop?.value
  );
}

function hasHoverVal(currentSpec: Record<string, any>, spec) {
  return currentSpec?.hover?.value && spec?.values?.hover?.value;
}

function isCTA(spec) {
  return spec.type === `CTA`;
}

function isAddButtonLabel(
  templateSpec,
  currentSpec: Record<string, any>,
  key: string,
) {
  return (
    key === `atcButton` &&
    templateSpec.type === `TEXT_EDIT` &&
    currentSpec.value === `ADD` &&
    templateSpec.values.value !== `ADD`
  );
}

function isGlobalStyleTypography(style: StyleTemplate, templateSpec) {
  return style.isGlobal && templateSpec.type === `TYPOGRAPHY`;
}

export function applyStyle(
  currentCustomizations: Array<Customization>,
  styleTemplate: StyleTemplate,
) {
  const template = styleTemplate.props.customizations;
  if (currentCustomizations.length === 0) {
    return template;
  }
  return produce(template, (draft) => {
    draft.forEach((templateCustomizations) => {
      templateCustomizations.components.forEach((templateComp) => {
        templateComp.specs.forEach((templateSpec) => {
          const currentSpec = findCurrentSpec(
            currentCustomizations,
            templateCustomizations,
            templateComp,
            templateSpec,
          );
          if (
            !currentSpec ||
            isAddButtonLabel(
              templateSpec,
              currentSpec,
              templateCustomizations.key,
            )
          ) {
            return;
          }
          if (
            isLinkOrTextOrMargin(templateSpec) ||
            isBGImgOrVid(templateSpec) ||
            isGlobalStyleTypography(styleTemplate, templateSpec)
          ) {
            if (currentSpec.value) {
              templateSpec.values.value = currentSpec.value;
            }
            if (hasDeskVal(currentSpec, templateSpec)) {
              templateSpec.values.value.desktop.value =
                currentSpec.value.desktop.value;
            }
            if (hasHoverVal(currentSpec, templateSpec)) {
              templateSpec.values.hover.value = currentSpec.hover.value;
            }
          } else if (isCTA(templateSpec)) {
            templateSpec.values.content = currentSpec.content;
          }
        });
      });
    });
  });
}

export function useIsSuperSystemUser() {
  const ctx = useContext(AccountContext);
  return [`naty@loomi.me`, `igor@loomi.me`, `matan@loomi.me`].includes(
    ctx.account.store.email,
  );
}

function findCurrentSpec(
  current: Array<Customization>,
  templateCust,
  templateComp,
  templateSpec,
): Record<string, any> | null {
  let currentSpec: CustomizationSpec | null = null;
  current.forEach((currentCust) => {
    if (currentCust.key === templateCust.key) {
      currentCust.components.forEach((currentComp) => {
        if (currentComp.key === templateComp.key) {
          currentComp.specs.forEach((spec) => {
            if (
              spec.key === templateSpec.key &&
              spec.type === templateSpec.type
            ) {
              currentSpec = spec;
            }
          });
        }
      });
    }
  });
  return currentSpec?.values;
}
