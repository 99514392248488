import React from 'react';
import styled from 'styled-components';

export function AppsCatalogGridDivider({
  children,
  longTiles,
}: {
  children: any;
  longTiles: boolean;
}) {
  return (
    <Wrapper className="apps-catalog-divider" longTiles={longTiles}>
      {children}
      <Divider />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1.5rem;
  color: #949ea7;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: -0.17px;
  padding: 2.5rem 0 2.5rem 1rem;
  max-width: ${(p) => (p.longTiles ? `57rem` : `65rem`)};
  align-items: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dcdcdc;
  opacity: 0.8;
`;
