import { useContext, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { EditorContext } from '@/features/editor/context/editor-context';
import { ScheduleType } from '@/webapi/use-experience-api';
import { dateToUtcInSeconds } from '@/features/details/utils';

const DAY_IN_SECONDS = 86400;

export function useScheduleExperience({ type }: { type: ScheduleType }) {
  const { experienceState } = useContext(EditorContext);
  const { currentExperience: experience, scheduleExperience } = experienceState;
  const { schedule } = experience;
  const [utcStart, setStartDate] = useState(
    schedule?.utcStart || dateToUtcInSeconds(),
  );
  const [utcEnd, setEndDate] = useState(
    schedule?.utcEnd || dateToUtcInSeconds() + DAY_IN_SECONDS,
  );
  const [timeZone, setTimeZone] = useState(
    schedule?.timeZone || {
      offset: 2,
      name: `Asia/Jerusalem`,
      label: `GMT+2:00 (Athens, Helsinki, Jerusalem)`,
    },
  );
  const [weekDay, setWeekDay] = useState(schedule?.weekDay || `SUNDAY`);
  const [startHour, setStartHour] = useState(schedule?.startHour || 12);
  const [startMinute, setStartMinute] = useState(schedule?.startMinute || 0);
  const [endHour, setEndHour] = useState(schedule?.endHour || 20);
  const [endMinute, setEndMinute] = useState(schedule?.endMinute || 0);

  const startTime = useMemo(() => {
    const date = new Date();
    date.setHours(startHour);
    date.setMinutes(startMinute);
    return date;
  }, [startHour, startMinute]);

  const endTime = useMemo(() => {
    const date = new Date();
    date.setHours(endHour);
    date.setMinutes(endMinute);
    return date;
  }, [endHour, endMinute]);

  const onSelectScheduleTimes = () => {
    const updated = produce(experience, (draft) => {
      draft.schedule = {
        ...schedule,
        ...{
          type,
          timeZone,
          utcStart,
          utcEnd,
          weekDay,
          startHour,
          startMinute,
          startSecond: 0,
          endHour,
          endMinute,
          endSecond: 0,
        },
      };
    });
    scheduleExperience(updated.schedule);
  };

  useEffect(() => {
    if (utcStart >= utcEnd) {
      setEndDate(Number(utcStart) + DAY_IN_SECONDS);
    }
  }, [utcStart]);

  useEffect(() => {
    onSelectScheduleTimes();
  }, [utcStart, utcEnd, startTime, endTime, weekDay, timeZone, type]);

  function onStartTimeChange(date: Date): void {
    setStartHour(date.getHours());
    setStartMinute(date.getMinutes());
  }
  function onEndTimeChange(date: Date): void {
    setEndHour(date.getHours());
    setEndMinute(date.getMinutes());
  }

  return {
    utcStart,
    utcEnd,
    timeZone,
    weekDay,
    setStartDate,
    setEndDate,
    setTimeZone,
    setWeekDay,
    startTime,
    endTime,
    onStartTimeChange,
    onEndTimeChange,
  };
}
