import { useContext } from 'react';
import { Experience, ExperienceTag } from '@/webapi/use-experience-api';
import { AccountContext, useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { maybe } from '@/features/details/utils';

export function isProfitMetricAvailable(metricStartsAt?: string | Date) {
  const ctx = useContext(AccountContext);
  if (
    window.location.hostname === `pre-prod--kind-noether-230989.netlify.app`
  ) {
    return true;
  }
  let profitAvailableSince = new Date(`2024-02-26T00:00:00`).valueOf();
  const alias = ctx?.account?.store?.alias;
  if (alias === `THE_OODIE_UK_1564994947`) {
    profitAvailableSince = new Date(`2024-02-01T00:00:00`).valueOf();
  }
  const isEnabled = useFeatureBit(FeatureBit.PROFIT_METRIC);
  const start = getMetricsStartDate();
  return (
    isEnabled &&
    metricStartsAt &&
    new Date(metricStartsAt).valueOf() > profitAvailableSince &&
    start?.valueOf() > profitAvailableSince
  );
}

function getMetricsStartDate() {
  return maybe(
    () => new Date(new URL(window.location.href).searchParams.get(`s`)),
  );
}

export function isBestSellersAvailable(metricStartsAt?: string | Date) {
  const isBestSellersAvailble = useFeatureBit(FeatureBit.BEST_SELLERS);
  const start = getMetricsStartDate();
  const bestSellersAvailableSince = new Date(`2024-03-26T00:00:00`).valueOf();
  if (
    window?.location?.hostname ===
      `pre-prod--kind-noether-230989.netlify.app` ||
    window?.location?.hostname === `vsly.local`
  ) {
    return true;
  }

  return (
    isBestSellersAvailble &&
    metricStartsAt &&
    new Date(metricStartsAt).valueOf() > bestSellersAvailableSince &&
    start?.valueOf() > bestSellersAvailableSince
  );
}

export function isReturnsMetricAvailable(metricStartsAt?: string | Date) {
  const isEnabled = useFeatureBit(FeatureBit.RETURNS_METRIC);
  if (
    window.location.hostname === `pre-prod--kind-noether-230989.netlify.app`
  ) {
    return true;
  }
  const start = getMetricsStartDate();
  const profitAvailableSince = new Date(`2024-02-27T00:00:00`).valueOf();
  return (
    isEnabled &&
    metricStartsAt &&
    new Date(metricStartsAt).valueOf() > profitAvailableSince &&
    start?.valueOf() > profitAvailableSince
  );
}

export enum MetricKind {
  M_UNKNOWN = `M_UNKNOWN`,
  REVENUE = `REVENUE`,
  CONVERSION_RATE = `CONVERSION_RATE`,
  AVG_ORDER_VALUE = `AVG_ORDER_VALUE`,
  TRANSACTIONS = `TRANSACTIONS`,
  SESSIONS = `SESSIONS`,
  EXIT_RATE = `EXIT_RATE`,
  PER_SESSION_VALUE = `PER_SESSION_VALUE`,
  ADD_TO_CART_RATE = `ADD_TO_CART_RATE`,
  SIGNUPS = `SIGNUPS`,
  SIGNUPS_RATE = `SIGNUPS_RATE`,

  PAGE_VIEWS = `PAGE_VIEWS`,
  ITEMS_PER_PURCHASE = `ITEMS_PER_PURCHASE`,
  PRODUCT_DETAILS_VIEWS_RATE = `PRODUCT_DETAILS_VIEWS_RATE`,
  PAGES_PER_SESSION = `PAGES_PER_SESSION`,
  AVG_SESSION_DURATION = `AVG_SESSION_DURATION`,
  // RECS ONLY
  ASSISTED_REVENUE = `ASSISTED_REVENUE`,
  DIRECT_REVENUE = `DIRECT_REVENUE`,
  DIRECT_TRANSACTIONS = `DIRECT_TRANSACTIONS`,
  ASSISTED_TRANSACTIONS = `ASSISTED_TRANSACTIONS`,
  // CONTENT ONLY
  REVENUE_FROM_CLICKS = `REVENUE_FROM_CLICKS`,
  CLICKS = `CLICKS`,
  CTR = `CTR`,
  SUBSCRIPTIONS = `SUBSCRIPTIONS`,
  SUBSCRIPTION_RATE = `SUBSCRIPTIONS_RATE`,
  PROFIT = `PROFIT`,
  PROFIT_PER_SESSION = `PROFIT_PER_SESSION`,
  RETURNS = `RETURNS`,
  RETURNS_PER_SESSION = `RETURNS_PER_SESSION`,
  CHECKOUT_RATE = `CHECKOUT_RATE`,

  // deprecated
  ADD_TO_CART = `ADD_TO_CART`,
  PRODUCT_DETAIL_VIEWS = `PRODUCT_DETAIL_VIEWS`,
}

const invalidPrimaryGoals = [
  MetricKind.AVG_SESSION_DURATION,
  MetricKind.PAGES_PER_SESSION,
];
export function getAllowedPrimaryGoals(list: MetricKind[]) {
  return list?.filter((g) => !invalidPrimaryGoals.includes(g)) || [];
}

const deprecatedMetrics = [
  MetricKind.ADD_TO_CART,
  MetricKind.PRODUCT_DETAIL_VIEWS,
];

const contentMetrics = [
  MetricKind.CTR,
  MetricKind.CLICKS,
  MetricKind.REVENUE_FROM_CLICKS,
];

const recsMetrics = [MetricKind.ASSISTED_REVENUE, MetricKind.DIRECT_REVENUE];

export function getAllowedGoals(goals: MetricKind[], tag: ExperienceTag) {
  const allowed = goals?.filter((g) => !deprecatedMetrics.includes(g));
  if (tag === ExperienceTag.CONTENT) {
    return allowed?.filter((g) => !recsMetrics.includes(g));
  }
  if (tag === ExperienceTag.RECS) {
    return allowed?.filter((g) => !contentMetrics.includes(g));
  }
  return allowed;
}
export interface MetricValue {
  name: MetricKind;
  value?: number;
}

export const allMetricDescriptions = new Map<MetricKind, string>([
  [
    MetricKind.CONVERSION_RATE,
    `Conversion rates are calculated by simply taking the number of orders and diving it by the number of sessions. For example if you had 50 conversions from 1,000 sessions, your conversion rate would be 5%, since 50 ÷ 1,000 = 5%.`,
  ],
  [MetricKind.SIGNUPS, `The total count of new user registrations`],
  [
    MetricKind.REVENUE,
    `The total revenue of all purchases made after viewing an experience. Purchases are only counted if they occur if a user viewd the experience. The revenue of one purchase might be attributed to more than one experience, in which case the revenue is counted twice.`,
  ],
  [
    MetricKind.REVENUE_FROM_CLICKS,
    `Revenue generated from products purchased after clicking on a visual element within the same session.`,
  ],
  [
    MetricKind.SESSIONS,
    `The period of time a user is active on your site. By default, if a user is inactive for 30 minutes or more, any future activity is attributed to a new session. Users that leave your site and return within 30 minutes are counted as part of the original session.`,
  ],
  [
    MetricKind.CHECKOUT_RATE,
    `Checkout rate is the percentage of visitors who reach the checkout page`,
  ],
  [
    MetricKind.AVG_ORDER_VALUE,
    `Your average order value is the average amount of a transaction. If you divide your total revenue by the total number of orders, you'll get your average order value. Typically, your average order value is also the value of a conversion.`,
  ],
  [
    MetricKind.PER_SESSION_VALUE,
    `Per session value is the average value of a session of your ecommerce website. The higher the per session value, the more valuable the traffic is for your ecommerce business.`,
  ],
  [
    MetricKind.ADD_TO_CART_RATE,
    `The number of times your site users added an item to their cart. Divided by the number of sessions`,
  ],
  [MetricKind.TRANSACTIONS, `The total number of transactions.`],
  [MetricKind.ITEMS_PER_PURCHASE, `The average number of items per purchase.`],
  [
    MetricKind.PRODUCT_DETAILS_VIEWS_RATE,
    `The total number of product details page views, divided by the number of sessions.`,
  ],
  [
    MetricKind.PAGES_PER_SESSION,
    `Refers to a website's total page views divided by the total number of sessions in a given period of time.`,
  ],
  [
    MetricKind.AVG_SESSION_DURATION,
    `This is the metric that measures the average length of sessions on a website (in seconds). Visually begins counting a session once a user lands on a site, and continues counting until the user exits the site or is inactive for a predetermined amount of time.`,
  ],
  [
    MetricKind.DIRECT_REVENUE,
    `Revenue generated from products bought after clicking on a recommended item and buying that item (or items from its group ID) within a 30-day window.`,
  ],
  [
    MetricKind.ASSISTED_REVENUE,
    `Revenue generated from products bought after clicking on a recommended item and buying any product within the session.`,
  ],
  [
    MetricKind.CTR,
    `Click-through rate is the ratio of users who click on a visually component to the number of total users who view a page.`,
  ],
  [MetricKind.CLICKS, `The number of clicks made by user on Visually widgets.`],
  [
    MetricKind.SUBSCRIPTIONS,
    `The number of products purchased in a subscription model.`,
  ],
  [
    MetricKind.SUBSCRIPTION_RATE,
    `The subscription rate is the ratio of users who purchase a subscription upon viewing a component to the total number of users who visit the page.`,
  ],
  [
    MetricKind.PROFIT,
    `Profit is calculated based on the variant cost defined in Shopify, Profit = quantity * ( price - cost ) - discount. If a variant has no defined cost it is ignored.`,
  ],
  [
    MetricKind.PROFIT_PER_SESSION,
    `Profit is calculated based on the variant cost defined in Shopify divided by the number of sessions, Profit = quantity * ( price - cost ) - discount. If a variant has no defined cost it is ignored. `,
  ],
  [MetricKind.RETURNS, `Refunded order line items`],
  [
    MetricKind.RETURNS_PER_SESSION,
    `Refunded order line items divided by sessions`,
  ],
]);

export function allMetricKinds(expr: Experience): MetricKind[] {
  const isEnabled = useFeatureBit(FeatureBit.SUBSCRIPTIONS_v1);
  const isEnterCheckoutAvailable = shouldShowCheckoutMetric(expr);
  return [
    MetricKind.CONVERSION_RATE,
    MetricKind.REVENUE,
    MetricKind.SESSIONS,
    MetricKind.AVG_ORDER_VALUE,
    MetricKind.PER_SESSION_VALUE,
    MetricKind.ADD_TO_CART_RATE,
    MetricKind.TRANSACTIONS,
    MetricKind.ITEMS_PER_PURCHASE,
    MetricKind.PRODUCT_DETAILS_VIEWS_RATE,
    MetricKind.PAGES_PER_SESSION,
    MetricKind.AVG_SESSION_DURATION,
    ...[MetricKind.PROFIT, MetricKind.PROFIT_PER_SESSION],
    ...[MetricKind.RETURNS, MetricKind.RETURNS_PER_SESSION],
    ...(isEnabled
      ? [MetricKind.SUBSCRIPTIONS, MetricKind.SUBSCRIPTION_RATE]
      : []),
    ...(isEnterCheckoutAvailable ? [MetricKind.CHECKOUT_RATE] : []),
    ...specificTagMetrics(expr.tag),
  ];
}

export function specificTagMetrics(tag: ExperienceTag) {
  return [
    ...(tag === ExperienceTag.CONTENT || tag === ExperienceTag.CUSTOM
      ? [MetricKind.CTR, MetricKind.CLICKS]
      : []),
  ];
}

function shouldShowCheckoutMetric(expr: Experience) {
  const enterCheckoutEnabled = useFeatureBit(
    FeatureBit.ENTERED_CHECKOUT_METRIC,
  );
  const hasCheckoutChange = maybe(() =>
    expr.variants
      ?.flatMap(
        (v) =>
          v?.changes?.map(
            (c) => !!c?.checkoutMountPointInfo?.mountPointSelector,
          ) || [],
      )
      ?.find((x) => x),
  );
  return (
    enterCheckoutEnabled &&
    !hasCheckoutChange &&
    isEnterCheckoutMetircAvailable(expr) &&
    !expr.isPostPurchase
  );
}

export interface Theme {
  id: number;
  name: string;
  role: string;
  previewable: boolean;
  theme_store_id?: number;
  updated_at: string;
}

function isEnterCheckoutMetircAvailable(expr: Experience) {
  return maybe(
    () =>
      new Date(expr.metricsStartAt).valueOf() >=
      new Date(`2024-07-07T00:00:00`).valueOf(),
  );
}
