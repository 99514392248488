import * as React from 'react';
import styled from 'styled-components';
import { useMemo, useRef } from 'react';
import { OverflowBottom, OverflowTop } from '@/components/overflow';
import { VSpace } from '@/components/spacing';

const DEFAULT_PADDING = 3;

export interface OverflowGradientProps {
  heightInRem?: number;
  spacingInRem?: number;
  turnPointInPercent?: number;
}

export interface GradientLayoutProps {
  preHeader?: any;
  header?: any;
  children?: any;
  footer?: any;
  footerJustify?: string;

  padding?: number;
  paddingBottom?: number;
  horizontalPadding?: number;
  contentSpacing?: number;
  contentPadding?: number;
  overflowTop?: OverflowGradientProps;
  overflowBottom?: OverflowGradientProps;

  headerTop?: number;

  noTopContentSpacing?: boolean;
  noBottomContentSpacing?: boolean;

  background?: string;
  stickyEnabled?: boolean;
  scrollRef?: React.MutableRefObject<HTMLElement>;
  noScroll?: boolean;
}

export function GradientLayout(props: GradientLayoutProps) {
  const {
    header,
    children,
    footer,
    preHeader,
    background,
    stickyEnabled,
    padding,
    paddingBottom,
    horizontalPadding,
    contentPadding,
    overflowBottom,
    overflowTop,
    contentSpacing,
    noBottomContentSpacing,
    noTopContentSpacing,
    headerTop,
    footerJustify,
    scrollRef,
    noScroll,
  } = props;

  const ctx = newGradientLayoutContext(scrollRef);

  const topOverflowHeight = useMemo(() => {
    if (stickyEnabled) {
      return `13rem`;
    }
    return `${overflowTop?.heightInRem || (padding || DEFAULT_PADDING) * 2}rem`;
  }, [overflowTop, stickyEnabled]);

  const topOverflowTop = useMemo(
    () => `${overflowTop?.spacingInRem || 0}rem`,
    [overflowTop],
  );

  const topOverflowTurnPoint = useMemo(
    () => `${overflowTop?.turnPointInPercent || 90}%`,
    [overflowTop],
  );

  const bottomOverflowHeight = useMemo(
    () =>
      `${overflowBottom?.heightInRem || (padding || DEFAULT_PADDING) * 2}rem`,
    [overflowBottom],
  );

  const bottomOverflowBottom = useMemo(
    () => `${overflowBottom?.spacingInRem || 0}rem`,
    [overflowBottom],
  );

  const bottomOverflowTurnPoint = useMemo(
    () => `${overflowBottom?.turnPointInPercent || 20}%`,
    [overflowBottom],
  );

  return (
    <GradientLayoutContext.Provider value={ctx}>
      <Wrapper padding={horizontalPadding || padding} background={background}>
        <OverflowTop
          height={topOverflowHeight}
          top={topOverflowTop}
          turnPoint={topOverflowTurnPoint}
          background={background}
          stickyEnabled={stickyEnabled}
        />
        <Header padding={padding} headerTop={headerTop}>
          {header}
        </Header>
        {preHeader && <PreHeader>{preHeader}</PreHeader>}
        <Content
          padding={contentPadding || padding}
          paddingBottom={paddingBottom}
        >
          <ShadowFixScroller
            noScroll={noScroll}
            id="vslyGradientScroller"
            ref={ctx.scrollRef}
          >
            {!noTopContentSpacing && (
              <VSpace value={contentSpacing || padding || DEFAULT_PADDING} />
            )}
            {children}
            {!noBottomContentSpacing && (
              <VSpace value={contentSpacing || padding || DEFAULT_PADDING} />
            )}
          </ShadowFixScroller>
        </Content>
        <OverflowBottom
          height={bottomOverflowHeight}
          bottom={bottomOverflowBottom}
          turnPoint={bottomOverflowTurnPoint}
          background={background}
        />
        <Footer
          padding={paddingBottom || padding}
          footerJustify={footerJustify}
        >
          {footer}
        </Footer>
      </Wrapper>
    </GradientLayoutContext.Provider>
  );
}

interface WP {
  headerTop?: number;
  padding?: number;
  paddingBottom?: number;
  background?: string;
  footerJustify?: string;
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;

  padding: 0 ${(p: WP) => `${p?.padding || DEFAULT_PADDING}rem`};
  background: rgb(${(p: WP) => p?.background || `255,255,255`});
  text-transform: none;
`;

const PreHeader = styled.div`
  z-index: 30;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: ${(p: WP) => `${p?.headerTop || p?.padding || DEFAULT_PADDING}rem`};
  left: 0;
  right: 0;
  width: 100%;
  height: auto;

  color: #000000;
  font-family: Inter, serif;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: -0.75px;
  text-align: center;
  z-index: 30;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;

  padding: ${(p: WP) => `${p?.padding || DEFAULT_PADDING}rem`} 0;
  ${(p: WP) =>
    p?.paddingBottom ? `padding-bottom: ${p.paddingBottom}rem;` : ``}
`;

const ShadowFixScroller = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: ${(p) => (p.noScroll ? `hidden` : `scroll`)};
  overflow-x: hidden;
  justify-items: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  margin: -10px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(p: WP) => `${p?.footerJustify || `center`}`};

  position: absolute;
  width: 100%;
  bottom: ${(p: WP) => `${p?.padding || DEFAULT_PADDING}rem`};
  left: 0;
  right: 0;
  color: #000000;
  font-family: Inter, serif;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: -0.75px;
  text-align: center;
  z-index: 33;
`;

export const GradientLayoutContext = React.createContext(
  {} as GradientLayoutCtx,
);

interface GradientLayoutCtx {
  scrollRef: React.MutableRefObject<HTMLElement>;
  scrollToEnd: (wait?: number) => void;
}

export function newGradientLayoutContext(
  ref?: React.MutableRefObject<HTMLElement>,
): GradientLayoutCtx {
  const scrollRef = ref || useRef<HTMLElement>(null);

  const scrollToEnd = (wait = 200) => {
    if (scrollRef?.current) {
      setTimeout(() => {
        scrollRef?.current?.scrollTo({
          top: scrollRef?.current?.scrollHeight,
          behavior: `smooth`,
        });
      }, wait);
    }
  };

  return {
    scrollRef,
    scrollToEnd,
  };
}
