import React from 'react';
import {
  GenericInputProps,
  InputType,
} from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { TextEditInput } from '@/features/editor/widgets/custom-widget/inputs/typography/text-edit';
import { FontControlsInput } from '@/features/editor/widgets/custom-widget/inputs/typography/font-controls';
import { SpacingInput } from '@/features/editor/widgets/custom-widget/inputs/layout/spacing-input';
import { BoxShadowInput } from '@/features/editor/widgets/custom-widget/inputs/decoration/box-shadow';
import { BorderInput } from '@/features/editor/widgets/custom-widget/inputs/decoration/border';
import { SizeInput } from '@/features/editor/widgets/custom-widget/inputs/layout/size-input';
import { SingleNumberInput } from '@/features/editor/widgets/custom-widget/inputs/shared/single-number-input';
import { TextShadowInput } from '@/features/editor/widgets/custom-widget/inputs/decoration/text-shadow';
import { BackgroundInput } from '@/features/editor/widgets/custom-widget/inputs/background';
import { LinkInput } from '@/features/editor/widgets/custom-widget/inputs/shared/link';
import { CtaInput } from '@/features/editor/widgets/custom-widget/inputs/actions/cta';
import { ColorSelectorInput } from '@/features/editor/widgets/custom-widget/inputs/color/color-selector-input';
import { DropdownRowInput } from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { DatePicker } from '@/features/editor/widgets/custom-widget/inputs/date-picker';
import { SwitchInput } from '@/features/editor/widgets/custom-widget/inputs/switch/switch';

export function GenericInput({
  customizationIdx,
  customization,
  componentIdx,
  component,
  specIdx,
  spec,
  device,
  showInputName,
  initialValues,
  onValuesChanged,
  hover,
}: GenericInputProps) {
  return (
    <ResolvedComponent
      customizationIdx={customizationIdx}
      customization={customization}
      componentIdx={componentIdx}
      component={component}
      specIdx={specIdx}
      spec={spec}
      device={device}
      showInputName={showInputName}
      initialValues={initialValues}
      onValuesChanged={onValuesChanged}
      hover={hover}
    />
  );
}

const ResolvedComponent = ({
  customizationIdx,
  customization,
  componentIdx,
  component,
  specIdx,
  spec,
  device,
  showInputName,
  initialValues,
  onValuesChanged,
  hover,
}) => {
  if (spec.type === InputType.DATE_PICKER)
    return (
      <DatePicker
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
      />
    );
  if (spec.type === InputType.TEXT_EDIT)
    return (
      <TextEditInput
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
      />
    );

  if (spec.type === InputType.TYPOGRAPHY)
    return (
      <FontControlsInput
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
      />
    );

  if (spec.type === InputType.PADDING)
    return (
      <SpacingInput
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
      />
    );

  if (spec.type === InputType.MARGIN)
    return (
      <SpacingInput
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
      />
    );

  if (spec.type === InputType.BOX_SHADOW)
    return (
      <BoxShadowInput
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
      />
    );

  if (spec.type === InputType.TEXT_SHADOW)
    return (
      <TextShadowInput
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
      />
    );

  if (spec.type === InputType.BORDER)
    return (
      <BorderInput
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
      />
    );

  if (spec.type === InputType.SIZE)
    return (
      <SizeInput
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
      />
    );

  if (spec.type === InputType.BACKGROUND)
    return (
      <BackgroundInput
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        showInputName={showInputName}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
        hover={hover}
      />
    );

  if (spec.type === InputType.LINK)
    return (
      <LinkInput
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
      />
    );

  if (spec.type === InputType.CTA)
    return (
      <CtaInput
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
        hover={hover}
      />
    );

  if (spec.type === InputType.SINGLE_UNIT)
    return (
      <SingleNumberInput
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
        caption={spec.name}
        envKey="value"
        unit={spec.values.unit}
      />
    );

  if (spec.type === InputType.COLOR) {
    return (
      <ColorSelectorInput
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
      />
    );
  }

  if (spec.type === InputType.SINGLE_SELECT) {
    return (
      <DropdownRowInput
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
        caption={spec.name}
      />
    );
  }
  if (spec.type === InputType.SWITCH) {
    return (
      <SwitchInput
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
      />
    );
  }

  return <span style={{ display: `none` }}>{spec.name}</span>;
};
