import React, { useContext, useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  EditorChangeKind,
  EditorDeclarativeBlock,
} from '@/webapi/use-experience-api';
import { EditorContext } from '@/features/editor/context/editor-context';
import { CSS, HTML, JS } from '@/features/editor/widgets/code-editors/utils';
import { useCodeEditor } from '@/features/editor/widgets/code-editors/use-code-editor';
import {
  CodeEditorFooter,
  CodeEditorIconButton,
  CodeEditorMonacoWrapper,
  CodeEditorScrollContainer,
  CodeEditorWrapper,
  UpperHeader,
} from '@/features/editor/widgets/code-editors/shared';
import { BackCloseHeader } from '@/features/editor/shared/back-close-header';
import { FloatingHeader } from '@/components/overflow';
import { Flexbox } from '@/components/flex';
import { DeviceType } from '@/utils/definitions';
import { TabSelect } from '@/components/tab-select';
import {
  CssCodeEditor,
  HtmlCodeEditor,
  JsCodeEditor,
} from '@/features/editor/widgets/code-editors/monaco-editor';
import { BigButton } from '@/components/big-button';

export interface CompoundCodeEditorWidgetProps {
  initialChange: EditorDeclarativeBlock;
  isHtmlEditable: boolean;
}

export function CompoundCodeEditorWidget({
  initialChange,
  isHtmlEditable,
}: CompoundCodeEditorWidgetProps) {
  const {
    devicePreview: {
      editorState: { device },
    },
    inspectorNav: { gotoChangelog },
  } = useContext(EditorContext);

  const {
    setCurrentEditor,
    currentEditor,
    onSave,
    onHide,
    onHtmlCodeChanged,
    onCssCodeChanged,
    onJsCodeChanged,
    isDirty,
    htmlCode,
    cssCode,
    jsCode,
  } = useCodeEditor(initialChange, isHtmlEditable);

  const [isFullscreen, setIsFullScreen] = useState(false);
  const onFullscreenClicked = () => {
    setIsFullScreen(!isFullscreen);
  };

  const onTabChange = (_: number, tab: string) => {
    setCurrentEditor(tab);
  };

  let tabs = [HTML, CSS, JS];
  if (initialChange.editorKind === EditorChangeKind.GLOBAL_JS) {
    tabs = [JS];
  } else if (initialChange.editorKind === EditorChangeKind.GLOBAL_CSS) {
    tabs = [CSS];
  } else if (initialChange.editorKind === EditorChangeKind.HIDE_COMPONENT) {
    tabs = [CSS];
  }

  useEffect(() => {
    if (!isFullscreen) {
      setTimeout(() => {
        const elem = document.querySelector(`#styled-editor`) as HTMLElement;
        if (elem) {
          elem.style.width = `1%`;
          elem.style.height = `1%`;

          setTimeout(() => {
            const elem = document.querySelector(
              `#styled-editor`,
            ) as HTMLElement;
            elem.style.width = `100%`;
            elem.style.height = `100%`;
          }, 20);
        }
      }, 5);
    }
  }, [isFullscreen]);

  return (
    <CodeEditorWrapper device={device} fullscreen={isFullscreen}>
      <FloatingHeader>
        <BackCloseHeader
          hideBack={isFullscreen}
          onBack={gotoChangelog}
          device={device}
          backCaption="< Back to experience"
          hideClose
        />
      </FloatingHeader>
      <div />
      <CodeEditorScrollContainer
        style={{ paddingTop: isFullscreen ? `0` : `2.5rem` }}
      >
        <div>
          <UpperHeader
            title="Code Editor"
            selector={initialChange.block.selector}
          />
          <TabsSection
            tabs={tabs}
            onTabChange={onTabChange}
            onFullscreenClicked={onFullscreenClicked}
            currentEditor={currentEditor}
          />
        </div>

        <CodeEditorMonacoWrapper id="styled-editor">
          {currentEditor === HTML && (
            <HtmlCodeEditor
              code={htmlCode}
              onChange={onHtmlCodeChanged}
              readOnly={!isHtmlEditable}
            />
          )}
          {currentEditor === CSS && (
            <CssCodeEditor
              code={cssCode}
              onChange={onCssCodeChanged}
              readOnly={false}
            />
          )}
          {currentEditor === JS && (
            <JsCodeEditor
              code={jsCode}
              onChange={onJsCodeChanged}
              readOnly={false}
            />
          )}
        </CodeEditorMonacoWrapper>
      </CodeEditorScrollContainer>
      <CodeEditorFooter currentEditor={currentEditor}>
        <CodeEditorIconButton onClick={onHide}>
          <StaticImage
            height={25}
            placeholder="none"
            src="../../../../assets/trash.svg"
            alt="delete"
          />
        </CodeEditorIconButton>
        <BigButton
          disabled={!isDirty}
          onClick={onSave}
          border="2px solid black"
          noTransform
          size={device === DeviceType.Desktop ? `medium` : `large`}
          fillWidth
        >
          Save
        </BigButton>
      </CodeEditorFooter>
    </CodeEditorWrapper>
  );
}

const TabsSection = ({
  tabs,
  onTabChange,
  onFullscreenClicked,
  currentEditor,
}) => (
  <Flexbox
    direction="row"
    align="center"
    justify="space-between"
    gap="2rem"
    padding="0.5rem 0 0 0"
    width="100%"
  >
    <div style={{ opacity: tabs.length === 1 ? `0` : `1` }}>
      <TabSelect
        defaultValue={currentEditor}
        tabHeight={2.4}
        tabWidth={9.2}
        containerPadding={0.25}
        tabs={tabs}
        onTabSelected={onTabChange}
      />
    </div>
    <CodeEditorIconButton onClick={onFullscreenClicked}>
      <StaticImage
        height={22}
        placeholder="none"
        src="../../../../assets/fullscreen.svg"
        alt="fullscreen"
      />
    </CodeEditorIconButton>
  </Flexbox>
);
