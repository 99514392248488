import { CachePolicies, useFetch } from 'use-http';
import { routes } from '@/webapi/routes';
import { Role } from '@/features/teammates/types';
import { getAliasQueryParam } from '@/utils/browser';

export function useAuthApi() {
  const { get, error, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: true,
    },
  }));

  const getUserDetails = (): Promise<UserDetails> => get(routes.userDetails());

  const getUserContext = (): Promise<StoreDetails> => {
    const route = aliasQueryParam
      ? `${routes.userContext()}?value=${aliasQueryParam}`
      : routes.userContext();
    return get(route);
  };

  const logout = () => get(routes.logout());

  const aliasQueryParam = getAliasQueryParam();
  return {
    loading,
    error,
    getUserContext,
    getUserDetails,
    logout,
  };
}

export enum Status {
  UNKNOWN,
  YES = 1,
  NO = 2,
}

export enum SubscriptionStatus {
  UNKNOWN = `UNKNOWN`,
  ACTIVE = `ACTIVE`,
  CANCELLED = `CANCELLED`,
  DECLINED = `DECLINED`,
  EXPIRED = `EXPIRED`,
  FROZEN = `FROZEN`,
  PENDING = `PENDING`,
}

export interface Subscription {
  shopId: string;
  status: SubscriptionStatus;
  createdAt: string;
  updatedAt: string;
}

export enum InstallationType {
  UNKLNOWN = `X`,
  CUSTOM = `CUSTOM`,
  PUBLIC = `PUBLIC`,
}

export interface StoreDetails {
  domain: string;
  alias: string;
  mainAlias?: string;
  currency: string;
  timezone: string;
  isShopifyPlus: boolean;
  hasCheckout: boolean;
  profilePic: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: Role;
  isUninstalled: boolean;
  isTestStore: boolean;
  installationType: InstallationType;
  subscription: Subscription;
  featureBits: Record<string, boolean>;
  userCreatedAt: string;
  shouldAllowAppEmbed: boolean;
  fastSyncOrdersCount: number;
  storeAnalysis?: StoreAnalysis;
}

export interface UserDetails {
  email: string;
  firstName: string;
  lastName: string;

  signupDateSeconds: number;
  daysToTrialEnd: number;
  storeDomain: string;
  storeAlias: string;

  ordersCount: number;
  liveExperiencesCount: number;
  draftExperiencesCount: number;
}

export interface StoreAnalysis {
  checkoutType: CheckoutType;
  cartType: string;
  cartRefreshType: string;
}

export enum CheckoutType {
  SHOPIFY_LEGACY_CHECKOUT = `SHOPIFY_LEGACY_CHECKOUT`,
  SHOPIFY_CHECKOUT_EXTENSIBILITY = `SHOPIFY_CHECKOUT_EXTENSIBILITY`,
}

export enum CartType {
  CART_PAGE = `CART_PAGE`,
  DRAWER = `DRAWER`,
  REBUY = `REBUY`,
}

export enum CartRefreshType {
  AUTO = `AUTO`,
  NAVIGATE_TO_CART_PAGE = `NAVIGATE`,
  RELOAD_PAGE = `RELOAD`,
  MANUAL = `MANUAL`,
}

export function isFeatureBitEnabled(
  featureBits: Record<string, boolean>,
  name: FeatureBit,
  fallback: boolean,
): boolean {
  if (!featureBits) return fallback;
  const val = featureBits[name];
  return val !== undefined ? val : fallback;
}

export enum FeatureBit {
  SHOW_TEST_WIDGETS = `editor-show-test-widgets`,
  SHOPIFY_THEMES = `shop-themes`,
  INSTAGRAM_INTEGRATION = `instagram`,
  INACTIVITY_TRIGGER = `inactivity-trigger`,
  IMAGE_COMPRESSION = `image-compression`,
  VISIT_IN_PAGE_AUDIENCE_CLIENT_SIDE = `visit_in_page`,
  MULTIVARIANT = `multivariant`,
  VARIANT_ATTRIBUTES = `variant-attributes`,
  ENHANCED_CART_TARGETING = `enhanced-cart-targeting`,
  SUBSCRIPTIONS_v1 = `subscriptions_v1`,
  ENTERED_CHECKOUT_METRIC = `entered-checkout-metric`,
  AI_IMAGE = `ai-images`,
  LAYERED_RULING_AND_OR = `advanced-ruling-and-or`,
  APP_EMBED_REVERT = `app-embed-revert`,
  EST_SIGNIFICANCE = `est-significance`,
  AUDIENCES = `new-metrics-backend`,
  PROFIT_METRIC = `profit-metrics`,
  BEST_SELLERS = `best-sellers`,
  RETURNS_METRIC = `returns-metric`,
  RECS_SAME_TAG_COL = `recs-same-tag-col`,
  SHOW_COLORS_SWATCH = `editor-show-color-swatches`,
  ENABLE_LOYALTY_TARGETING = `loyalty-targeting`,
  ENABLE_WELCOME_QUIZ = `enable-welcome-quiz`,
  DOWNLOAD_EXP_ORDERS = `download-orders`,
  RAW_DATA_EXPORT = `raw-data-export`,
  NEW_REC_ALGS = `new-rec-algs`,
  SHOW_AUDIENCE_ON_REPORTS_PAGE = `audience-on-result-screen`,
  STORE_STYLES = `store-styles`,
  DEFAULT_STORE_STYLE = `default-store-style`,
  ENABLE_POST_PURCHASE_EXPERIMENTAL_OPTIONS = `enable-post-purchase-experimental-options`,
  ENABLE_V2_FTUE = `enable_v2_ftue`,
  ENABLE_UPSELLS_DISCOUNTS = `enable-upsells-discounts`,
  FETCH_ENTIRE_CATALOG = `enable-fetch-entire-catalog`,
  ENABLE_INSPIRATIONS = `enable-inspirations`,
  UNSAVE_FILTER_OPTS = `rec-opts-unsave`,
  CUSTOM_AUDIENCES = `custom_audiences`,
  CUSTOM_AUDIENCES_TAB = `custom_audiences_tab`,
  LOW_STOCK_WIDGET = `low-stock-widget`,
  OOS_PRICE_PLACEMENT = `price-oos-placement`,
  CHECKOUT_EXTENSIBILITY = `allocate-checkout-extensibility`,
  SPA_INTEGRATION = `integration-spa`,
  DASHBOARD_SHOW_ARCHIVED_STATS = `dashboard-show-archived-stats`,
}
