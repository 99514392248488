import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { HSpace } from '@/components/spacing';
import { EditorContext } from '@/features/editor/context/editor-context';
import { ExperienceStatus } from '@/webapi/use-experience-api';
import {
  PublishModal,
  usePublishModal,
} from '@/features/editor/use-publish-modal';
import { handleInvalidAlloc } from '@/features/editor/widgets/targeting/shared';

export function PendingChangesMessage() {
  const {
    experienceState: { currentExperience },
  } = useContext(EditorContext);
  const [isShown, setIsShown] = useState(false);
  const publishRef = useRef(null);
  const publishProps = usePublishModal(publishRef);

  const onPublish = () => {
    if (handleInvalidAlloc(currentExperience)) {
      return;
    }
    publishProps.show();
  };

  const onIgnore = () => {
    setIsShown(false);
  };

  useEffect(() => {
    if (
      currentExperience.status === ExperienceStatus.PUBLISHED &&
      currentExperience?.updatedAt &&
      currentExperience?.publishedAt
    ) {
      const updated = Math.floor(
        new Date(currentExperience.updatedAt).getTime() / 1000,
      );
      const published = Math.floor(
        new Date(currentExperience?.publishedAt).getTime() / 1000,
      );

      if (updated > published) {
        setIsShown(true);
      } else {
        setIsShown(false);
      }
    }
  }, [
    currentExperience.updatedAt,
    currentExperience.publishedAt,
    currentExperience.status,
  ]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <PublishModal {...publishProps} />
      {isShown && (
        <Wrapper>
          Please note that there are saved changes pending for publish
          <HSpace value={2} />
          <PrimaryButton ref={publishRef} onClick={onPublish}>
            Publish
          </PrimaryButton>
          <HSpace value={1.5} />
          <SecondaryButton onClick={onIgnore}>Ignore</SecondaryButton>
        </Wrapper>
      )}
    </>
  );
}

const RevealAnimation = keyframes`
  from {
    opacity: 0;
    transform: scale(1, 0);
  }

  to {
    opacity: 1;
    transform: scale(1, 1);
  }
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 4rem;
  background: #ffd2ac;
  padding: 0 2rem;

  font-family: Inter, serif;
  font-weight: 400;
  font-size: 1.3rem;
  color: #bd5600;

  animation: ${RevealAnimation} 0.5s ease-in-out;
`;

const PrimaryButton = styled.button`
  cursor: pointer;
  appearance: none;
  outline: none;
  background: #ff953c;
  border: 1.5px solid #ff953c;
  color: white;
  border-radius: 1rem;
  padding: 0.2rem 1.2rem;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Inter, serif;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.6;
  }
`;

const SecondaryButton = styled(PrimaryButton)`
  && {
    border: 1.5px solid #ff953c;
    background: transparent;
    color: #ff953c;
  }
`;
