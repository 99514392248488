import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import md5 from 'blueimp-md5';
import { EditorContext } from '@/features/editor/context/editor-context';
import {
  CatalogCheckoutApp,
  CatalogCheckoutWidget,
  InputComponent,
  InputCustomization,
  InputSpec,
} from '@/webapi/checkout-widget-props';
import {
  mutateFn,
  useComplexState,
  useStateWithHistory,
} from '@/utils/use-complex-state';
import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';
import { CheckoutWidgetChange } from '@/pkg/sdk';
import {
  EDITOR_ACTIVE_BLOCK_CACHE,
  useCachedAutoSave,
} from '@/components/hooks/use-cached-auto-save';
import {
  AdvancedRulingOption,
  LoadingStrategyOption,
  RecommendationOptions,
} from '@/webapi/use-widget-catalog-api';
import { QBItemSelection } from '@/components/query-builder/models';
import { routes } from '@/webapi/routes';
import { defaultCondition } from '@/features/editor/widgets/custom-widget/loading-section/util/defaultCondition';
import { AccountContext } from '@/features/account-context';
import { recommendProducts } from '@/features/editor/widgets/post-purchase/use-offers';
import { useDetachedState } from '@/components/hooks/use-detached-state';

export const CheckoutExtensibilityContext =
  React.createContext<CheckoutExtensibilityCtx>({} as CheckoutExtensibilityCtx);

export interface CheckoutExtensibilityCtx {
  currentStep: CheckoutExtensibilityAppsStep;
  moveToAppListing: () => void;
  moveToWidgetListing: (app: CatalogCheckoutApp) => void;
  moveToWidgetStyle: (
    app: CatalogCheckoutApp,
    widget: CatalogCheckoutWidget,
  ) => void;
  moveToWidgetStyleAdvanced: (
    app: CatalogCheckoutApp,
    widget: CatalogCheckoutWidget,
  ) => void;
  onBack: () => void;
  onSave: () => void;

  currentApp: CatalogCheckoutApp;
  currentWidget: CatalogCheckoutWidget;
  getValueByPath: (
    custId: number,
    compId: number,
    specId: number,
  ) => { jsonPath: string; value: any };
  getPartsByPath: (path: string) => {
    custId: number;
    cust: InputCustomization;
    compId: number;
    comp: InputComponent;
    specId: number;
    spec: InputSpec;
  };
  currentProps: any;
  setCurrentProps: (fn: mutateFn<any>) => void;
  newChange: EditorDeclarativeBlock;
  setNewChange: (fn: mutateFn<EditorDeclarativeBlock>) => void;
  redo: () => void;
  undo: () => void;
  canRedo: boolean;
  canUndo: boolean;
  undoRedoCount: number;
  isUpdateWidgetFlow: boolean;

  updateRecommendationOptions: (options: RecommendationOptions) => void;
  updateAdvancedRulingOptions: (options: Array<AdvancedRulingOption>) => void;
  updateRecommendationCondition: (options: Array<QBItemSelection>) => void;
  updateLoadingCfg: (option: LoadingStrategyOption) => void;

  getCustomizationStatus: (custKey: string) => boolean;
  toggleCustomizationStatus: (custKey: string) => void;
}

export function newCheckoutExtensibilityContext(
  initialStep: CheckoutExtensibilityAppsStep,
  origChange: EditorDeclarativeBlock,
): CheckoutExtensibilityCtx {
  const {
    account: {
      store: { alias },
    },
  } = useContext(AccountContext);

  const {
    experienceState: {
      upsertEditorChange,
      currentExperience: { id: experienceId },
    },
    devicePreview: { leaveAnchor },
    inspectorNav: { gotoChangelog },
    applyTempChange,
    checkoutApps,
  } = useContext(EditorContext);

  const [currentStep, setCurrentStep] = useState(initialStep);

  const [currentApp, setCurrentApp] = useState<CatalogCheckoutApp | null>(
    getCheckoutAppFromWidget(
      origChange.checkoutWidgetManifest?.manifest?.id,
      checkoutApps,
    ),
  );

  const [currentWidget, setCurrentWidget] = useState<CatalogCheckoutWidget>(
    origChange.checkoutWidgetManifest,
  );

  const [newChange, setNewChange] = useComplexState(origChange);
  useCachedAutoSave(newChange, experienceId, EDITOR_ACTIVE_BLOCK_CACHE);

  const isUpdateWidgetFlow = useMemo(
    () => initialStep === CheckoutExtensibilityAppsStep.WIDGET_STYLE,
    [initialStep],
  );

  const [products, setProducts, productsRef] = useDetachedState([]);

  const {
    state: currentProps,
    produceDispatch: setCurrentProps,
    redo,
    undo,
    canRedo,
    canUndo,
    undoRedoCount,
  } = useStateWithHistory<any | undefined>(
    (origChange?.block?.value as CheckoutWidgetChange).props ||
      currentWidget?.initialState ||
      {},
    `WIDGET_${
      (newChange?.block?.value as CheckoutWidgetChange)?.sectionId
    }_HISTORY`,
  );

  useEffect(() => {
    if (currentStep === CheckoutExtensibilityAppsStep.APP_LISTING) {
      EDITOR_ACTIVE_BLOCK_CACHE.remove(experienceId);
      EDITOR_ACTIVE_BLOCK_CACHE.disableWrites();
      setCurrentProps(() => null);
      return;
    }

    if (currentStep === CheckoutExtensibilityAppsStep.WIDGET_LISTING) {
      EDITOR_ACTIVE_BLOCK_CACHE.remove(experienceId);
      EDITOR_ACTIVE_BLOCK_CACHE.disableWrites();
      setCurrentProps(() => null);
    }

    if (
      currentStep === CheckoutExtensibilityAppsStep.WIDGET_STYLE ||
      currentStep === CheckoutExtensibilityAppsStep.WIDGET_STYLE_ADVANCED
    ) {
      setCurrentProps(
        () =>
          (newChange?.block?.value as CheckoutWidgetChange)?.props || {
            ...currentWidget.initialState,
            products: productsRef?.current,
          },
      );
    }

    EDITOR_ACTIVE_BLOCK_CACHE.enableWrites();
  }, [currentStep, currentWidget]);

  useEffect(() => {
    if (currentProps?.loadingEnv && currentProps?.loadingValue) {
      recommendProducts(currentProps.loadingEnv).then((recs) => {
        setProducts([...recs]);
      });
    }
  }, [currentProps]);

  useEffect(() => {
    setCurrentProps((draft) => {
      draft.products = productsRef.current;
    });
  }, [products]);

  useEffect(() => {
    if (currentProps && currentWidget) {
      setNewChange((draft) => {
        (draft.block.value as CheckoutWidgetChange).props = currentProps;
      });
    }
  }, [currentProps]);

  useEffect(() => {
    applyTempChange(newChange);
  }, [newChange]);

  const getValueByPath = useCallback(
    (
      custId: number,
      compId: number,
      specId: number,
    ): { jsonPath: string; value: any } => {
      try {
        const cust = currentWidget.manifest.customizations[custId];
        const comp =
          currentWidget.manifest.customizations[custId].components[compId];
        const spec =
          currentWidget.manifest.customizations[custId].components[compId]
            .specs[specId];

        const path = `${cust.key}.${comp.key}.${spec.key}`;
        return {
          jsonPath: path,
          value: currentProps[cust.key][comp.key][spec.key],
        };
      } catch (e) {
        return {
          jsonPath: ``,
          value: null,
        };
      }
    },
    [currentWidget, currentProps],
  );

  const getPartsByPath = useCallback(
    (path: string) => {
      const parts = path.split(`.`);
      if (parts.length === 3) {
        const custId = currentWidget?.manifest?.customizations?.findIndex(
          (cust) => cust.key === parts[0],
        );
        const compId = currentWidget?.manifest?.customizations?.[
          custId
        ]?.components?.findIndex((comp) => comp.key === parts[1]);

        const specId = currentWidget?.manifest?.customizations?.[
          custId
        ]?.components?.[compId]?.specs?.findIndex(
          (spec) => spec.key === parts[2],
        );

        return {
          custId,
          cust: currentWidget?.manifest?.customizations?.[custId],
          compId,
          comp: currentWidget?.manifest?.customizations?.[custId]?.components?.[
            compId
          ],
          specId,
          spec: currentWidget?.manifest?.customizations?.[custId]?.components?.[
            compId
          ].specs[specId],
        };
      }
      return null;
    },
    [currentWidget, currentProps],
  );

  const onBack = () => {
    if (
      isUpdateWidgetFlow &&
      currentStep === CheckoutExtensibilityAppsStep.WIDGET_STYLE
    ) {
      leaveAnchor();
      gotoChangelog();
      return;
    }

    switch (currentStep) {
      case CheckoutExtensibilityAppsStep.APP_LISTING:
        leaveAnchor();
        gotoChangelog();
        break;
      case CheckoutExtensibilityAppsStep.WIDGET_LISTING:
        moveToAppListing();
        break;
      case CheckoutExtensibilityAppsStep.WIDGET_STYLE:
        moveToWidgetListing(currentApp);
        break;
      case CheckoutExtensibilityAppsStep.WIDGET_STYLE_ADVANCED:
        moveToWidgetStyle(currentApp, currentWidget);
        break;
      default:
        break;
    }
  };

  const onSave = () => {
    upsertEditorChange(newChange);
    leaveAnchor();
    gotoChangelog();
  };

  const moveToAppListing = () => {
    setCurrentApp(null);
    setCurrentWidget(null);
    setCurrentStep(CheckoutExtensibilityAppsStep.APP_LISTING);
  };

  const moveToWidgetListing = (app: CatalogCheckoutApp) => {
    setCurrentApp(app);
    setCurrentWidget(null);
    setCurrentStep(CheckoutExtensibilityAppsStep.WIDGET_LISTING);
  };

  const moveToWidgetStyle = (
    app: CatalogCheckoutApp,
    widget: CatalogCheckoutWidget,
  ) => {
    setCurrentApp(app);
    setCurrentWidget(widget);
    setCurrentStep(CheckoutExtensibilityAppsStep.WIDGET_STYLE);
  };

  const moveToWidgetStyleAdvanced = (
    app: CatalogCheckoutApp,
    widget: CatalogCheckoutWidget,
  ) => {
    setCurrentApp(app);
    setCurrentWidget(widget);
    setCurrentStep(CheckoutExtensibilityAppsStep.WIDGET_STYLE_ADVANCED);
  };

  const updateRecommendationOptions = (option: RecommendationOptions) => {
    setCurrentProps((draft) => {
      if (hasNoLoadingEnv(draft)) {
        draft.loadingEnv = {};
      }

      draft.loadingEnv.recommendationOptions = {
        env: routes.getEnv(),
        appId: `upsells`,
        ...draft.loadingEnv.recommendationOptions,
        ...option,
      };
    });
  };

  const updateAdvancedRulingOptions = (
    options: Array<AdvancedRulingOption>,
  ) => {
    setCurrentProps((draft) => {
      if (hasNoLoadingEnv(draft)) {
        draft.loadingEnv = {};
      }

      if (draft.loadingEnv?.recommendationOptions?.advancedRuling?.length > 0) {
        draft.loadingEnv.recommendationOptions.advancedRuling = options;
      } else {
        draft.loadingEnv.recommendationOptions = {
          env: routes.getEnv(),
          appId: `upsells`,
          ...draft.loadingEnv.recommendationOptions,
          advancedRuling: options,
        };
      }
    });
  };

  const updateRecommendationCondition = (condition: Array<QBItemSelection>) => {
    setCurrentProps((draft) => {
      draft.loadingEnv.recommendationOptions.env = routes.getEnv();
      draft.loadingEnv.recommendationOptions.conditionId = md5(
        JSON.stringify(condition),
      );
      draft.loadingEnv.recommendationOptions.condition = condition;
    });
  };

  const updateLoadingCfg = (option: LoadingStrategyOption) => {
    const defCond = defaultCondition(option.value.type, `upsells`);
    setCurrentProps((draft) => {
      const prevType = draft?.loadingEnv?.recommendationOptions?.type;
      if (!draft) return;
      draft.loadingValue = option;

      const { loadingEnv } = draft;
      if (!!loadingEnv && !!loadingEnv.recommendationOptions) {
        draft.loadingEnv.recommendationOptions.type = option.value.type;
        if (option.value.type !== prevType) {
          // @ts-ignore
          loadingEnv.recommendationOptions.condition = defCond;
          loadingEnv.recommendationOptions.conditionId = md5(
            JSON.stringify(defCond),
          );
        }
      } else {
        draft.loadingEnv = {
          recommendationOptions: {
            appId: `upsells`,
            env: routes.getEnv(),
            type: option.value.type,
            storeAlias: alias,
            condition: defCond,
            conditionId: md5(JSON.stringify(defCond)),
          } as RecommendationOptions,
        };
      }
    });
  };

  const getCustomizationStatus = (custKey: string): boolean =>
    currentProps?.[custKey]?.[`isDisabled`] === true;

  const toggleCustomizationStatus = (custKey: string): void => {
    const current = getCustomizationStatus(custKey);
    setCurrentProps((draft) => {
      draft[custKey][`isDisabled`] = !current;
    });
  };

  return {
    currentStep,
    moveToAppListing,
    moveToWidgetListing,
    moveToWidgetStyle,
    moveToWidgetStyleAdvanced,
    onBack,
    onSave,
    currentApp,
    currentWidget,
    getValueByPath,
    getPartsByPath,
    currentProps,
    setCurrentProps,
    newChange,
    setNewChange,
    redo,
    undo,
    canRedo,
    canUndo,
    undoRedoCount,
    isUpdateWidgetFlow,
    updateRecommendationOptions,
    updateAdvancedRulingOptions,
    updateRecommendationCondition,
    updateLoadingCfg,
    getCustomizationStatus,
    toggleCustomizationStatus,
  };
}

function getCheckoutAppFromWidget(
  widgetId: string,
  apps: CatalogCheckoutApp[],
) {
  return apps?.find?.((app) =>
    app?.checkoutWidgets?.some?.((widget) => widget.manifest.id === widgetId),
  );
}

function hasNoLoadingEnv(draft: any) {
  return typeof draft?.loadingEnv === `undefined`;
}

export enum CheckoutExtensibilityAppsStep {
  APP_LISTING = `APP_LISTING`,
  WIDGET_LISTING = `WIDGET_LISTING`,
  WIDGET_STYLE = `WIDGET_STYLE`,
  WIDGET_STYLE_ADVANCED = `WIDGET_STYLE_ADVANCED`,
}
