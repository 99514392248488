import { useMemo } from 'react';
import { CatalogApp } from '@/webapi/use-widget-catalog-api';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

export function useAppsList(appsCatalog: CatalogApp[]) {
  const shouldShowTestWidgets = useFeatureBit(
    FeatureBit.SHOW_TEST_WIDGETS,
    false,
  );

  const filterTestsApp = (app: CatalogApp) => ![`tests`].includes(app.id);

  const testApp = useMemo(
    () => appsCatalog.find((app) => app?.id?.includes(`tests`)),
    [appsCatalog],
  );

  const visibleApps = useMemo(
    () => appsCatalog.filter(filterTestsApp),
    [appsCatalog, shouldShowTestWidgets],
  );

  return {
    visibleApps,
    testApp,
    shouldShowTestWidgets,
  };
}
