import { CachePolicies, useFetch } from 'use-http';
import { routes } from '@/webapi/routes';

export function useStoreSettingsApi() {
  const { get, post, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: false,
    },
  }));

  const listReviewsProviders = async (): Promise<ReviewProviders> =>
    (await get(routes.reviewsEndpoint())) as ReviewProviders;

  const setReviewsProvider = async (
    providerName: ReviewProviderName,
  ): Promise<void> => post(routes.reviewsEndpoint(), { value: providerName });

  return {
    listReviewsProviders,
    setReviewsProvider,
    loading,
  };
}

export interface ReviewsData {
  reviewsProviders: ReviewProviders;
  reviewsEnabled: boolean;
  hasReviewProviders: boolean;
}

export interface ReviewProviders {
  providers?: ReviewProvider[];
}

export interface ReviewProvider {
  name?: ReviewProviderName;
  canBeSelected?: boolean;
  isSelected?: boolean;
}

export enum ReviewProviderName {
  NA = `____NA`,
  LOOX = `LOOX`,
  OKENDO = `OKENDO`,
  SHOPIFY = `SHOPIFY`,
  KLAVIYO = `KLAVIYO`,
  STAMPED = `STAMPED`,
  YOTPO = `YOTPO`,
}
