/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { CatalogAppTile } from '@/features/editor/widgets/shared/apps-catalog/app-tile';
import {
  CodeBlockApp,
  FakeClickApp,
  PageRedirectApp,
  PostPurchaseApp,
} from '@/features/editor/widgets/shared/apps-catalog/static-apps';
import { CatalogApp } from '@/webapi/use-widget-catalog-api';
import { useAppsList } from '@/features/editor/widgets/shared/apps-catalog/use-apps-list';
import { EditorContext } from '@/features/editor/context/editor-context';
import { getAppStarterQueryParam, setQueryParam } from '@/utils/browser';
import { AccountContext } from '@/features/account-context';
// @ts-ignore
import postPurchaseImage from '@/features/editor/widgets/shared/apps-catalog/post_purchase.svg';
import { usePostPurchaseNav } from '@/features/editor/widgets/post-purchase/use-post-purchase-nav';
import { DeviceType } from '@/utils/definitions';
import { AppsCatalogGridDivider } from '@/features/editor/widgets/shared/apps-catalog/divider';

export interface CatalogAppsListProps {
  appsCatalog: CatalogApp[];
  onCustomWidget: (app: CatalogApp, setLoading?: (b: boolean) => void) => void;
  onFakeClick: () => void;
  onCodeBlock: () => void;
  onPageRedirect: () => void;
  onCodeBlockSelected: (w: CatalogApp) => void;
  longTiles?: boolean;
}

function getPostPurchaseApp(isPostPurchaseEnabled: boolean) {
  if (isPostPurchaseEnabled) {
    return {
      id: `post-purchase`,
      name: `Add Another Post Purchase Page`,
      hint: `Add special offers right after the checkout step`,
      image: postPurchaseImage,
    };
  }
  return PostPurchaseApp;
}

export function CatalogAppsList({
  appsCatalog,
  onCustomWidget,
  onFakeClick,
  onCodeBlock,
  onPageRedirect,
  onCodeBlockSelected,
  longTiles,
}: CatalogAppsListProps) {
  const {
    account: {
      store: { isTestStore },
    },
  } = useContext(AccountContext);
  const { visibleApps, testApp, shouldShowTestWidgets } =
    useAppsList(appsCatalog);
  const {
    previewLoading,
    experienceState: { currentVariantIdx, currentExperience },
    resources,
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);

  const isDesk = useMemo(() => device === DeviceType.Desktop, [device]);

  const appStarter = getAppStarterQueryParam();
  if (appStarter && !previewLoading && !isTestStore) {
    setTimeout(() => {
      const app = appsCatalog.filter((app) => app.id === appStarter)?.[0];

      if (appStarter === FakeClickApp.id) onFakeClick();
      if (appStarter === CodeBlockApp.id) onCodeBlock();

      if (app) {
        onCustomWidget(app);
      }
      setQueryParam(`app`, ``);
    }, 500);
  }
  const { navigateToPostPurchaseExperience } = usePostPurchaseNav();

  const shouldDisablePageRedirect = useMemo(
    () => currentExperience?.variants?.[currentVariantIdx]?.changes?.length > 0,
    [currentExperience, currentVariantIdx],
  );

  return (
    <Wrapper>
      {!longTiles && (
        <AppsCatalogGridDivider longTiles={longTiles}>
          Visual Blocks
        </AppsCatalogGridDivider>
      )}
      <CatalogAppsGrid longTiles={longTiles} isDesk={isDesk}>
        {visibleApps.map((app) => (
          <CatalogAppTile
            key={app.id}
            app={app}
            onClick={onCustomWidget}
            longTiles={longTiles}
            isDesk={isDesk}
          />
        ))}
        {!longTiles && (
          <CatalogAppTile
            app={CodeBlockApp.widgets[0]}
            onClick={() => onCodeBlockSelected(CodeBlockApp.widgets[0])}
            longTiles={longTiles}
            isDesk={isDesk}
          />
        )}
      </CatalogAppsGrid>

      <AppsCatalogGridDivider longTiles={longTiles}>
        General
      </AppsCatalogGridDivider>
      <CatalogAppsGrid longTiles={longTiles} isDesk={isDesk}>
        <CatalogAppTile
          app={PageRedirectApp}
          onClick={onPageRedirect}
          longTiles={longTiles}
          isDesk={isDesk}
          isDisabled={shouldDisablePageRedirect}
          disabledText="To run a redirect test, you need to create a new, blank experience"
        />
        <CatalogAppTile
          app={FakeClickApp}
          onClick={onFakeClick}
          longTiles={longTiles}
          isDesk={isDesk}
        />
        <CatalogAppTile
          app={CodeBlockApp.widgets[1]}
          onClick={() => onCodeBlockSelected(CodeBlockApp.widgets[1])}
          longTiles={longTiles}
          isDesk={isDesk}
        />
        <CatalogAppTile
          app={CodeBlockApp.widgets[2]}
          onClick={() => onCodeBlockSelected(CodeBlockApp.widgets[2])}
          longTiles={longTiles}
          isDesk={isDesk}
        />
      </CatalogAppsGrid>

      <AppsCatalogGridDivider longTiles={longTiles}>
        New Pages
      </AppsCatalogGridDivider>
      <CatalogAppsGrid longTiles={longTiles} isDesk={isDesk}>
        <CatalogAppTile
          app={getPostPurchaseApp(resources.hasActivePostPurchase)}
          onClick={navigateToPostPurchaseExperience}
          longTiles={longTiles}
          isDesk={isDesk}
        />
      </CatalogAppsGrid>

      {shouldShowTestWidgets && !!testApp && (
        <>
          <AppsCatalogGridDivider longTiles={longTiles}>
            Testing Zone
          </AppsCatalogGridDivider>
          <CatalogAppsGrid longTiles={longTiles} isDesk={isDesk}>
            <CatalogAppTile
              app={testApp}
              onClick={onCustomWidget}
              longTiles={longTiles}
              isDesk={isDesk}
            />
          </CatalogAppsGrid>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .apps-catalog-divider:first-of-type {
    padding-top: 0;
  }
`;

export const CatalogAppsGrid = styled.div<{
  longTiles?: boolean;
  isDesk?: boolean;
}>`
  width: 100%;
  display: grid;
  grid-gap: ${(p) => (p.longTiles ? `2rem` : `3rem`)};
  align-items: center;
  justify-content: flex-start;

  grid-template-columns: ${(p) =>
    // eslint-disable-next-line no-nested-ternary
    p.longTiles ? (p.isDesk ? `40rem` : `50rem`) : `repeat(auto-fit, 31rem)`};
  grid-auto-rows: ${(p) => (p.longTiles ? `12rem` : `unset`)};
  ${(p) =>
    p.longTiles
      ? `
  .mixed-card-layout {
    grid-template-columns: 8rem 1fr;
  }
  `
      : ``}
`;
