import { CachePolicies, useFetch } from 'use-http';
import {
  EditorDeclarativeBlock,
  PlacementKind,
} from '@/webapi/use-experience-api';
import { routes } from '@/webapi/routes';
import { QBItemSelection } from '@/components/query-builder/models';
import { DeviceType } from '@/utils/definitions';

import { SlotState } from '@/features/editor/widgets/custom-widget/loading-section/shared/models';
import { RuleState } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';

export function useWidgetCatalogApi(_loading: boolean) {
  const { get, post, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: _loading,
    },
  }));

  const getAllApps = async () => {
    const res = (await get(routes.listAllApps())) as { apps: CatalogApp[] };
    if (res?.apps) {
      res.apps = res?.apps?.map((app) => {
        app.widgets = app?.widgets?.map((w) => {
          w.blueprint.schema.appId = app.id;
          return w;
        });
        return app;
      });
    }
    return res;
  };

  const getChange = async (experienceId: string, loomiId: string) =>
    (await post(routes.getChange(), {
      experienceId,
      loomiId,
    })) as {
      change: EditorDeclarativeBlock;
    };

  return { getAllApps, getChange, loading };
}

export interface CatalogApp {
  id: string;
  name: string;
  hint: string;
  image: string;
  widgets?: CatalogWidget[];
  relatedPlacements?: PlacementKind[];
  isHidden?: boolean;
}

export interface CatalogWidget {
  id: string;
  version?: string;
  name: string;
  image: string;
  hint: string;
  blueprint: WidgetBlueprint;
  relatedPlacements?: PlacementKind[];
  isHidden?: boolean;
}

export interface CatalogWidgetProps {
  customizations: Customization[];
  settings: Settings;
  groups?: Groups;
  appId?: string;
  styleTemplateId?: string;
  tsSelected?: number;
  blueprintName?: string;
  suggestAltTet?: boolean;
}

export interface Groups {
  primary: string;
  mapping: Record<string, string[]>;
}

export function getRecStrategy(currentParams: CatalogWidgetProps) {
  return currentParams?.settings?.loading?.value?.value;
}

export interface WidgetBlueprint {
  script: string;
  schema: CatalogWidgetProps;
}

export interface Customization {
  key: string;
  name: string;
  canBeDisabled?: boolean;
  isDisabled?: boolean;
  hideOn?: DeviceType[];
  hideWhen?: string;
  components: Component[];
  hasHoverState?: boolean;
}

export interface Component {
  key: string;
  name: string;
  hideOn?: DeviceType[];
  hideWhen?: string;
  specs: CustomizationSpec[];
}

export interface Settings {
  quickEdit: QuickEdit[];
  general?: GeneralSetting;
  loading?: RecStrategy;
  uiByGroup?: string;
  quickEditGroups?: QuickEditGroup[];
}

export interface QuickEditGroup {
  name: string;
  quickEdit: QuickEdit[];
}

export interface RecStrategy {
  title: string;
  value: LoadingStrategyOption;
  loadingEnv?: LoadingEnv;
  options: LoadingStrategyOption[];
}

export enum RecommendationType {
  MANUAL = `MANUAL`,
  CART_ITEMS = `CART_ITEMS`,
  PERSONALIZED = `PERSONALIZED`,
  MOST_POPULAR = `MOST_POPULAR`,
  NEW_ARRIVALS = `NEW_ARRIVALS`,
  VIEWD_TOGETHER = `VIEWD_TOGETHER`,
  RECENTLY_VIEWED = `RECENTLY_VIEWED`,
  PURCHASED_TOGETHER = `PURCHASED_TOGETHER`,
  ADVANCED_RULING = `ADVANCED_RULING`,
  PAST_PURCHASES = `PAST_PURCHASES`,
  MOST_CHEAP_IN_CART = `MOST_CHEAP_IN_CART`,
  MOST_EXPENSIVE_IN_CART = `MOST_EXPENSIVE_IN_CART`,
  VIEWED_WITH_RECENTLY_VIEWED = `VIEWED_WITH_RECENTLY_VIEWED`,
  PURCHASED_WITH_RECENTLY_PURCHASED = `PURCHASED_WITH_RECENTLY_PURCHASED`,
}

export interface LoadingEnv {
  recommendationOptions?: RecommendationOptions;
}

export interface ManualProduct {
  productId: string;
  mainValue: string;
  imageOverride: string;
}

export interface RecommendationOptions {
  type: RecommendationType;
  conditions?: any;
  strategyPerSlot?: Array<SlotState>;
  layeredRuling?: Array<RuleState>;
  productId?: string;
  productIds?: Array<string>;
  manualProducts?: Array<ManualProduct>;
  size?: number;
  storeAlias: string;
  env: number;
  conditionId?: string;
  condition?: Array<QBItemSelection>;
  // deprecated - use layeredRuling
  advancedRuling?: Array<AdvancedRulingOption>;
  appId?: string;
  widgetContext?: any;
}

export interface AdvancedRulingOption {
  num: number;
  manualProducts?: Array<ManualProduct>;
  itemSelection?: string;
  condition?: Array<QBItemSelection>;
}

export interface LoadingStrategyOption {
  label: string;
  description?: string;
  value: {
    default?: boolean;
    fn: string;
    template: string;
    type: RecommendationType;
  };
}

export interface QuickEdit {
  selector: string;
}

export interface GeneralSetting {
  title: string;
  key: string;
  specs: Spec[];
}

export interface CustomizationSpec {
  noAi?: boolean;
  explain?: string;
  key: string;
  name?: string;
  type: string;
  initialState?: boolean;
  hideOn?: DeviceType[];
  hideWhen?: string;
  values: Record<string, any>;
}

export interface Spec {
  key: string;
  type: string;
  hidden?: boolean;
  hideWhen?: string;
  value: Record<string, any>;
  metadata: Record<string, any>;
}

export interface PageRedirectOptions {
  sourceUrlProps?: Array<QBItemSelection>;
  targetUrl?: string;
}
