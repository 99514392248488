import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  QBItemProps,
  QBItemSelection,
} from '@/components/query-builder/models';
import { Flexbox } from '@/components/flex';
import { QBRootValue } from '@/components/query-builder/root-value';
import {
  QBButton,
  QBCaption,
  QBVSpace,
} from '@/components/query-builder/shared';
import { createQBContext, QBContext } from '@/components/query-builder/context';
import { OnboardingQueryBuilder } from '@/components/query-builder/onboarding';

type Props = {
  initialProps: QBItemProps;
  initialState: QBItemSelection[];
  onChange?: (value: QBItemSelection[]) => void;
  disableAndBtn?: boolean;
  whereNotion?: string;
  textCondNoMarginTop?: boolean;
  selectMaxWidth?: number;
};

export function QueryBuilder({
  initialProps,
  initialState,
  onChange,
  disableAndBtn,
  whereNotion,
  textCondNoMarginTop,
  selectMaxWidth,
}: Props) {
  const ctx = createQBContext(initialState, initialProps, onChange);
  const { appendKey } = ctx;

  return (
    <>
      <OnboardingQueryBuilder obsRef={ctx.onboardingRef} />
      <QBContext.Provider value={ctx}>
        <Wrapper singleRow={textCondNoMarginTop}>
          <QueryBuilderInner
            selectMaxWidth={selectMaxWidth}
            whereNotion={whereNotion}
            textCondNoMarginTop={textCondNoMarginTop}
          />
          {!disableAndBtn && (
            <AndButton onClick={() => appendKey(true)}>+ And</AndButton>
          )}
          <QBVSpace />
        </Wrapper>
      </QBContext.Provider>
    </>
  );
}

function QueryBuilderInner({
  whereNotion,
  textCondNoMarginTop,
  selectMaxWidth,
}: {
  whereNotion?: string;
  textCondNoMarginTop?: boolean;
  selectMaxWidth?: number;
}) {
  const {
    queryState,
    firstOptions,
    onboardingRef: [, refSetter],
  } = useContext(QBContext);
  return (
    <>
      {queryState.map((q, idx) => (
        <React.Fragment key={`query-${q?.qbProps?.caption}${idx}`}>
          <QBVSpace />
          <Row ref={refSetter} noContent={!whereNotion && idx === 0}>
            {idx === 0 ? (
              <Where>{whereNotion || ``}</Where>
            ) : (
              <LogicalAnd>And</LogicalAnd>
            )}
            <QBRootValue
              idx={idx}
              options={firstOptions()}
              selectMaxWidth={selectMaxWidth}
              textCondNoMarginTop={textCondNoMarginTop}
            />
          </Row>
        </React.Fragment>
      ))}
    </>
  );
}

const Wrapper = styled(Flexbox)`
  && {
    height: 100%;
    ${({ singleRow }: { singleRow?: boolean }) =>
      singleRow ? `` : `overflow-y: scroll;`}
    ${({ singleRow }: { singleRow?: boolean }) =>
      singleRow ? `` : `overflow-x: hidden;`}


    flex-direction: ${({ singleRow }: { singleRow?: boolean }) =>
      singleRow ? `row` : `column`};
    flex-wrap: nowrap;

    align-items: flex-start;
    justify-content: flex-start;

    /* Dont Remove, it fixes the box shadow of the AND button being cut off */
    margin: -10px;
    padding: 10px;
    /* Dont Remove, it fixes the box shadow of the AND button being cut off */
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: ${(p: any) => (p?.noContent ? `0` : `3rem`)} 1fr;

  grid-gap: ${(p: any) => (p?.noContent ? `0` : `2rem`)};
  align-items: flex-start;
  margin-bottom: 2rem;
  width: 100%;
`;

const LogicalAnd = styled(QBCaption)`
  && {
    margin-top: 0.65rem;
  }
`;

const AndButton = styled(QBButton)`
  && {
    width: 15rem;
  }
`;

const Where = styled(QBCaption)`
  && {
    margin-top: 0.65rem;
    margin-right: 1rem;
  }
`;
