import styled from 'styled-components';
import { DeviceType } from '@/utils/definitions';

export interface EditorLayoutProps {
  device: DeviceType;
  previewLoading: boolean;
}

export const EditorWrapper = styled.div`
  && {
    background: #f8f9fb;
    overflow: hidden;
    display: grid;
    grid-template-columns: ${(props: EditorLayoutProps) =>
      props.device === DeviceType.Desktop
        ? `2fr 1fr`
        : `minmax(60rem, 150rem) 88rem`};
    grid-template-areas: 'device inspector';

    ${(props: EditorLayoutProps) =>
      props.previewLoading ? `cursor: wait;` : ``}

    * {
      ${(props: EditorLayoutProps) =>
        props.previewLoading ? `pointer-events: none;` : ``}
    }
  }
`;

export const EditorDeviceSection = styled.div`
  && {
    grid-area: device;
    height: 100vh;
    min-height: 72rem;
    background: #f8f9fb;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 2rem 0;
  }
`;

export const EditorInspectorSection = styled.div`
  && {
    background: white;
    position: relative;
    grid-area: inspector;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    max-height: 100vh;
    min-height: 100vh;
    border-top-left-radius: 3.5rem;
    border-bottom-left-radius: 3.5rem;
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.03),
      0 18px 26px 0 rgba(177, 217, 203, 0.04), 0 1px 7px 0 rgba(0, 0, 0, 0.03);
  }
`;
