/* eslint-disable no-promise-executor-return */
import React, { useContext, useEffect, useState } from 'react';
import md5 from 'blueimp-md5';
import useAsyncEffect from 'use-async-effect';
import { diff } from 'jsondiffpatch';
import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';
import {
  AdvancedRulingOption,
  CatalogApp,
  CatalogWidget,
  CatalogWidgetProps,
  Component,
  Customization,
  CustomizationSpec,
  GeneralSetting,
  LoadingStrategyOption,
  RecommendationOptions,
  Spec,
} from '@/webapi/use-widget-catalog-api';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { AccountContext, useFeatureBit } from '@/features/account-context';
import { QBItemSelection } from '@/components/query-builder/models';
import { AnchorOrigin } from '@/features/editor/context/use-device-preview';
import { routes } from '@/webapi/routes';
import { schemaToEnv } from '@/features/editor/widgets/custom-widget/env';
import { useMeasureWidgetImageAspectRatio } from '@/features/editor/widgets/custom-widget/shared/use-measure-widget-image-aspect-ratio';
import { wait } from '@/utils/browser';
import {
  EDITOR_ACTIVE_BLOCK_CACHE,
  useCachedAutoSave,
} from '@/components/hooks/use-cached-auto-save';
import { useRuleEvaluator } from '@/features/editor/widgets/custom-widget/shared/use-rule-evaluator';
import { useStateWithHistory } from '@/utils/use-complex-state';
import { TEMP_NO_SELECTOR } from '@/features/editor/widgets/changelog/placeholder';
import {
  getOriginExperienceId,
  getOriginExperienceName,
} from '@/features/editor/shared/external_experience_widget';
import { isColor } from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/utils';
import { InputType } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { currencyValue } from '@/utils/currencies';
import { defaultCondition } from '@/features/editor/widgets/custom-widget/loading-section/util/defaultCondition';
import { useStyleTemplatingApi } from '@/features/editor/widgets/custom-widget/style-templating/useStyleTemplatingApi';
import { StyleTemplate } from '@/features/editor/widgets/custom-widget/style-templating/models';
import { FeatureBit } from '@/webapi/use-auth-api';
import {
  createStoreStyleForWidget,
  STORE_STYLE_ID,
} from '@/features/editor/widgets/custom-widget/shared/store-style-utils';

export const CustomWidgetContext = React.createContext(
  {} as CustomWidgetContextProps,
);

export function newCustomWidgetContext(
  change: EditorDeclarativeBlock,
  catalogApp: CatalogApp,
  widget: CatalogWidget | undefined,
  firstStep: CustomWidgetStep,
  experienceId: string,
  cachedChange: EditorDeclarativeBlock | undefined,
): CustomWidgetContextProps {
  const {
    account: {
      store: { alias },
    },
  } = useContext(AccountContext);

  const {
    resources: { appsCatalog },
    previewLoading,
    applyTempChange,
    transpiler: { asWidgetChange },
    devicePreview: {
      anchor,
      updateWidgetProps,
      editorState: { device },
    },
  } = useContext(EditorContext);

  const { measurements, imgMeasurementForHover } =
    useMeasureWidgetImageAspectRatio();

  const [currentStep, setCurrentStep] = useState(firstStep);
  const [currentWidget, setCurrentWidget] = useState<CatalogWidget | undefined>(
    widget || undefined,
  );
  const [isRerenderRequired, setIsRerenderRequired] = useState(false);

  useEffect(() => {
    if (currentStep === CustomWidgetStep.SELECT) {
      EDITOR_ACTIVE_BLOCK_CACHE.disableWrites();
    } else {
      EDITOR_ACTIVE_BLOCK_CACHE.enableWrites();
    }
  }, [currentStep]);

  const {
    state: currentSchema,
    produceDispatch: setCurrentSchema,
    redo,
    undo,
    canRedo,
    canUndo,
    undoRedoCount,
  } = useStateWithHistory<CatalogWidgetProps | undefined>(
    getInitialState(change, cachedChange, currentWidget),
    `WIDGET_${change?.editorId}_HISTORY`,
  );

  const origChange = cachedChange || change;
  const [newChange, setNewChange] = useState({ ...origChange });

  const { evaluateRule } = useRuleEvaluator(currentSchema, currentStep, device);

  useCachedAutoSave(newChange, experienceId, EDITOR_ACTIVE_BLOCK_CACHE);
  const [styleTemplateChange, setStylesTemplateChange] = useState(0);

  const gotoSelect = async () => {
    setCurrentWidget(undefined);
    setCurrentSchema(() => undefined, true);
    await EDITOR_ACTIVE_BLOCK_CACHE.remove(experienceId);
    setCurrentStep(CustomWidgetStep.SELECT);
  };

  const gotoCustomize = (widget: CatalogWidget, selectFromList: boolean) => {
    // on widget tile click
    setCurrentWidget(widget);
    const props = currentSchema || widget.blueprint.schema;
    setCurrentSchema(
      () =>
        selectFromList
          ? {
              ...props,
              tsSelected: new Date().valueOf(),
              blueprintName: widget?.name,
            }
          : props,
      true,
    );

    setCurrentStep(CustomWidgetStep.CUSTOMIZE);
  };

  const setStyleTemplate = (
    customizations: Array<Customization>,
    generalSettings: GeneralSetting,
    id: string,
  ) => {
    setCurrentSchema((draft) => {
      draft.customizations = customizations;
      draft.styleTemplateId = id;
      if (generalSettings?.specs?.length > 0 && id !== STORE_STYLE_ID) {
        draft.settings.general = generalSettings;
      }
    });
    setStylesTemplateChange(styleTemplateChange + 1);
  };
  const gotoStyle = (widget: CatalogWidget) => {
    setCurrentWidget(widget);
    // on rainbow customize click
    const props = currentSchema || widget.blueprint.schema;
    setCurrentSchema(() => props, true);
    setCurrentStep(CustomWidgetStep.STYLE);
  };

  const updateRecommendationOptions = (option: RecommendationOptions) => {
    setCurrentSchema((draft) => {
      if (hasNoLoadingEnv(draft)) {
        draft.settings.loading.loadingEnv = {};
      }

      draft.settings.loading.loadingEnv.recommendationOptions = {
        env: routes.getEnv(),
        appId: currentSchema.appId,
        ...draft.settings.loading.loadingEnv.recommendationOptions,
        ...option,
      };
    });
  };

  const updateAdvancedRulingOptions = (
    options: Array<AdvancedRulingOption>,
  ) => {
    setCurrentSchema((draft) => {
      if (hasNoLoadingEnv(draft)) {
        draft.settings.loading.loadingEnv = {};
      }

      if (
        draft.settings.loading.loadingEnv?.recommendationOptions?.advancedRuling
          ?.length > 0
      ) {
        draft.settings.loading.loadingEnv.recommendationOptions.advancedRuling =
          options;
      } else {
        draft.settings.loading.loadingEnv.recommendationOptions = {
          env: routes.getEnv(),
          appId: currentSchema.appId,
          ...draft.settings.loading.loadingEnv.recommendationOptions,
          advancedRuling: options,
        };
      }
    });
  };

  const updateRecommendationCondition = (condition: Array<QBItemSelection>) => {
    setCurrentSchema((draft) => {
      draft.settings.loading.loadingEnv.recommendationOptions.env =
        routes.getEnv();
      draft.settings.loading.loadingEnv.recommendationOptions.conditionId = md5(
        JSON.stringify(condition),
      );
      draft.settings.loading.loadingEnv.recommendationOptions.condition =
        condition;
    });
  };

  const updateCustomizationStatus = (custIdx: number, value: boolean) => {
    setCurrentSchema((draft) => {
      draft.customizations[custIdx].isDisabled = value;
      return draft;
    });
  };

  const updateLoadingCfg = (option: LoadingStrategyOption) => {
    const defCond = defaultCondition(option.value.type, currentSchema?.appId);

    setCurrentSchema((draft) => {
      const prevType =
        draft.settings.loading?.loadingEnv?.recommendationOptions?.type;
      draft.settings.loading.value = option;

      const { loadingEnv } = draft.settings.loading;
      if (!!loadingEnv && !!loadingEnv.recommendationOptions) {
        draft.settings.loading.loadingEnv.recommendationOptions.type =
          option.value.type;
        if (option.value.type !== prevType) {
          // @ts-ignore
          loadingEnv.recommendationOptions.condition = defCond;
          loadingEnv.recommendationOptions.conditionId = md5(
            JSON.stringify(defCond),
          );
        }
      } else {
        draft.settings.loading.loadingEnv = {
          recommendationOptions: {
            appId: currentSchema.appId,
            env: routes.getEnv(),
            type: option.value.type,
            storeAlias: alias,
            condition: defCond,
            conditionId: md5(JSON.stringify(defCond)),
          } as RecommendationOptions,
        };
      }
    });
  };

  const getCustomizationByIdx = (idx: number): Customization =>
    currentSchema.customizations[idx];

  const getComponentByIdx = (custIdx: number, compIdx: number): Component =>
    currentSchema.customizations[custIdx].components[compIdx];

  const getSpecByIdx = (
    custIdx: number,
    compIdx: number,
    specIdx: number,
  ): CustomizationSpec =>
    currentSchema.customizations[custIdx].components[compIdx].specs[specIdx];

  const getResponsiveValue = (
    custId: number,
    compId: number,
    specId: number,
    key: string,
    device: DeviceType,
    hover?: boolean,
  ): any => {
    const spec = getSpecByIdx(custId, compId, specId);
    if (device === DeviceType.Desktop) {
      if (hover) {
        const found = spec?.values?.[`desktop`]?.[`hover`]?.[key];
        if (found) return found;
      } else {
        const found = spec?.values?.[`desktop`]?.[key];
        if (found) return found;
      }
    }
    return spec?.values?.[key];
  };

  const setResponsiveValue = (
    custId: number,
    compId: number,
    specId: number,
    key: string,
    value: any,
    device: DeviceType,
    ignoreHistory?: boolean,
    forceSameVersion?: boolean,
    hover?: boolean,
  ) => {
    setCurrentSchema(
      (draft) => {
        function getSpec() {
          return draft.customizations[custId].components[compId].specs[specId];
        }

        const nextSpec = clone(getSpec());
        let prevValue: any;

        function hasHoverState() {
          return nextSpec?.values?.desktop && nextSpec?.values?.desktop?.hover;
        }

        if (device === DeviceType.Desktop) {
          if (!nextSpec.values?.[`desktop`]) nextSpec.values[`desktop`] = {};
          if (hover && hasHoverState()) {
            prevValue = nextSpec?.values?.[`desktop`]?.[`hover`]?.[key];
            nextSpec.values.desktop.hover[key] = value;
          } else {
            prevValue = nextSpec?.values?.[`desktop`]?.[key];
            nextSpec.values[`desktop`][key] = value;
            if (hasHoverState()) {
              nextSpec.values.desktop.hover[key] = value;
            }
          }
        } else {
          prevValue = nextSpec?.values?.[key];
          nextSpec.values[key] = value;
          if (nextSpec.values[`desktop`]) {
            delete nextSpec.values[`desktop`];
          }
        }

        if (diff(prevValue, value)) {
          if (isBGFirstEdit(prevValue, value, nextSpec)) {
            nextSpec.initialState = false;
          }
          draft.customizations[custId].components[compId].specs[specId] =
            nextSpec;
        }
      },
      ignoreHistory,
      forceSameVersion,
    );
  };

  const isBGFirstEdit = (
    prevValue: any,
    value: any,
    nextSpec: CustomizationSpec,
  ) =>
    nextSpec?.type === InputType.BACKGROUND &&
    nextSpec?.initialState &&
    isColor(prevValue) &&
    isColor(value) &&
    JSON.stringify(prevValue) === JSON.stringify(value) &&
    diff(prevValue, value).length > 1;

  const setGeneralCustomization = (specIdx: number, value: any) => {
    setCurrentSchema((draft) => {
      const spec = draft.settings.general.specs[specIdx];
      if (device === DeviceType.Desktop) {
        spec.value[`desktop`] = value;
      } else {
        spec.value = value;
      }
      draft.settings.general.specs[specIdx] = spec;
    });
  };

  const setUIStateByGroup = (value: any) => {
    const newVal = value?.toLowerCase().replace(` `, `_`);
    setCurrentSchema((draft) => {
      if (draft?.settings?.uiByGroup) {
        draft.settings.uiByGroup = newVal;
      }
    });
  };

  useEffect(() => {
    if (currentWidget && currentSchema) {
      const activeChange = asWidgetChange(
        origChange,
        currentWidget,
        currentSchema,
      );
      setNewChange(activeChange);
    }
  }, [currentWidget, currentSchema]);

  useEffect(() => {
    setIsRerenderRequired(true);
  }, [currentSchema?.settings?.loading, currentSchema?.settings?.general]);

  useEffect(() => {
    if (!previewLoading) {
      if (!currentSchema || isRerenderRequired) {
        applyTempChange(newChange);
        setIsRerenderRequired(false);
      } else {
        const propsSelector = `#vslyp-${newChange.editorSelector.replace(
          `#`,
          ``,
        )}`;
        const props = schemaToEnv(
          currentSchema,
          newChange.editorId,
          getOriginExperienceName(newChange),
          getOriginExperienceId(newChange),
        );
        updateWidgetProps(propsSelector, props);
      }
    }
  }, [newChange, previewLoading]);

  useAsyncEffect(
    async (isActive) => {
      if (!previewLoading && newChange?.block?.selector !== TEMP_NO_SELECTOR) {
        await wait(1300);
        if (isActive) {
          anchor(newChange.editorSelector, AnchorOrigin.NEW_ELEMENT);
        }
      }
    },
    [device, previewLoading],
  );

  function shouldOpenModalOnInit() {
    return (
      new Date().valueOf() - (currentSchema?.tsSelected || 0) < 100 &&
      [`Video`, `Image`].includes(currentSchema?.blueprintName)
    );
  }

  const styleTemplates = useListStyleTemplates(
    currentSchema,
    styleTemplateChange,
    device,
    appsCatalog,
  );

  return {
    styleTemplates,
    styleTemplateChange,
    setStyleTemplate,
    currentStep,
    currentWidget,
    setCurrentWidget,
    catalogApp,
    gotoSelect,
    gotoCustomize,
    gotoStyle,
    newChange,
    setNewChange,
    origChange,
    updateLoadingCfg,
    setGeneralCustomization,
    getCustomizationByIdx,
    getComponentByIdx,
    getSpecByIdx,
    getResponsiveValue,
    setResponsiveValue,
    currentSchema,
    updateRecommendationOptions,
    updateRecommendationCondition,
    updateAdvancedRulingOptions,
    updateCustomizationStatus,
    measurements,
    imgMeasurementForHover,
    evaluateRule,
    undo,
    redo,
    canUndo,
    canRedo,
    undoRedoCount,
    shouldOpenModalOnInit,
    setUIStateByGroup,
  };
}

export interface CustomWidgetContextProps {
  styleTemplates: Array<StyleTemplate>;
  styleTemplateChange: number;
  setStyleTemplate: (
    customizations: Array<Customization>,
    generalSettings: GeneralSetting,
    id: string,
  ) => void;
  currentStep: CustomWidgetStep;
  currentWidget: CatalogWidget;
  setCurrentWidget: (blueprint: CatalogWidget) => void;
  catalogApp: CatalogApp;
  gotoSelect: () => void;
  gotoCustomize: (blueprint: CatalogWidget, selectFromList?: boolean) => void;
  gotoStyle: (blueprint: CatalogWidget) => void;
  newChange: EditorDeclarativeBlock;
  setNewChange: (block: EditorDeclarativeBlock) => void;
  origChange: EditorDeclarativeBlock;
  updateLoadingCfg: (option: LoadingStrategyOption) => void;
  setGeneralCustomization: (specIdx: number, value: any) => void;
  setUIStateByGroup: (value: any) => void;
  getCustomizationByIdx: (idx: number) => Customization;
  getComponentByIdx: (custIdx: number, compIdx: number) => Component;
  getSpecByIdx: (
    custIdx: number,
    compIdx: number,
    specIdx: number,
  ) => CustomizationSpec;
  getResponsiveValue: (
    custId: number,
    compId: number,
    specId: number,
    key: string,
    device: DeviceType,
    hover?: boolean,
  ) => any;
  setResponsiveValue: (
    custId: number,
    compId: number,
    specId: number,
    key: string,
    value: any,
    device: DeviceType,
    ignoreHistory?: boolean,
    forceSameVersion?: boolean,
    hover?: boolean,
  ) => void;
  currentSchema: CatalogWidgetProps;
  updateRecommendationOptions: (options: RecommendationOptions) => void;
  updateAdvancedRulingOptions: (options: Array<AdvancedRulingOption>) => void;
  updateRecommendationCondition: (options: Array<QBItemSelection>) => void;
  updateCustomizationStatus: (custIdx: number, value: boolean) => void;
  measurements: Record<string, DOMRect>;
  imgMeasurementForHover: Record<string, DOMRect>;
  evaluateRule: (expr: string) => boolean;
  undo: () => void;
  canUndo: boolean;
  redo: () => void;
  canRedo: boolean;
  undoRedoCount: number;
  shouldOpenModalOnInit: () => boolean;
}

export enum CustomWidgetStep {
  SELECT = 1,
  CUSTOMIZE = 2,
  STYLE = 3,
}

function hasNoLoadingEnv(draft: any) {
  return typeof draft?.settings?.loading?.loadingEnv === `undefined`;
}

function hasNoCurrencyValue(spec: Spec) {
  return spec?.key === `currency` && spec?.value?.value?.length === 0;
}

const getDefaultCurrencyValue = (options: any[]): any => {
  const defaultValue = currencyValue();
  const { label, value } = options.find(
    (option) => option?.value === defaultValue,
  );
  return {
    desktop: { label, value },
    label,
    value,
  };
};

function clone(spec1: any) {
  return JSON.parse(JSON.stringify(spec1));
}

function getInitialState(
  change: EditorDeclarativeBlock,
  cachedChange: EditorDeclarativeBlock,
  currentWidget: CatalogWidget,
) {
  const initial =
    change.widgetProps ||
    cachedChange?.widgetProps ||
    currentWidget?.blueprint?.schema;
  if (typeof initial === `undefined`) {
    return undefined;
  }
  const initialState = clone(initial) || undefined;

  initialState?.settings?.general?.specs?.forEach((spec, idx) => {
    if (hasNoCurrencyValue(spec)) {
      initialState.settings.general.specs[idx].value = getDefaultCurrencyValue(
        spec?.metadata?.options,
      );
    }
  });
  return initialState;
}

function useListStyleTemplates(
  currentSchema: CatalogWidgetProps,
  styleTemplateChange: number,
  device: DeviceType,
  appsCatalog: CatalogApp[],
) {
  useContext(EditorContext);
  const [styleTemplates, setStyleTemplates] = useState<Array<StyleTemplate>>(
    [],
  );
  const { listStylesById } = useStyleTemplatingApi();
  const isEnabled = useFeatureBit(FeatureBit.STORE_STYLES);
  useEffect(() => {
    if (currentSchema?.blueprintName && currentSchema?.appId) {
      (async () => {
        const currentStyles = await listStylesById(
          currentSchema.blueprintName,
          currentSchema.appId,
        );
        const storeStyle = createStoreStyleForWidget(
          currentSchema,
          device,
          appsCatalog,
          currentStyles,
        );
        const templates = [];
        storeStyle && isEnabled && templates.push(storeStyle);
        currentStyles?.templates?.forEach((t) => {
          templates.push(t);
        });
        setStyleTemplates(templates);
      })();
    }
  }, [
    currentSchema?.blueprintName,
    currentSchema?.appId,
    styleTemplateChange,
    device,
  ]);
  return styleTemplates;
}
