import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import './rich-text-css-settings.css';
import {
  CustomToolbarRichText,
  fontFamilyOptions,
  modules,
} from '@/features/editor/widgets/custom-widget/inputs/typography/custom-toolbar-rich-text';
import { EditorContext } from '@/features/editor/context/editor-context';

const ReactQuill =
  typeof window === `object` ? require(`react-quill`) : () => false;
export interface RichTextEditorProps {
  defaultValue: string;
  onChange: (ev: any) => void;
  ref: any;
  defaultAttributes: object;
}
export function RichTextEditor({
  defaultValue,
  defaultAttributes,
  ref,
  onChange,
}: RichTextEditorProps) {
  const [updatedFonts, setUpdatedFonts] = useState([]);
  const [isRendered, setIsRendered] = useState(false);
  const [colorValue, setColorValue] = useState(`black`);
  const [fontSizeValue, setFontSizeValue] = useState(`13`);
  const [letterSpacingValue, setLetterSpacingValue] = useState(`0`);
  const [lineHeightValue, setLineHeightValue] = useState(`unset`);

  const editorRef = useRef(null);

  const {
    devicePreview: {
      editorState: { installedFonts },
    },
  } = useContext(EditorContext);

  useEffect(() => {
    const defaultFonts = fontFamilyOptions.filter(
      (font) =>
        !installedFonts.some(
          (option) => option.toLowerCase() === font.toLowerCase(),
        ),
    );
    const updated = [...installedFonts, ...defaultFonts];
    setUpdatedFonts(updated);
  }, [installedFonts]);

  useEffect(() => {
    if (editorRef.current) {
      const quill = editorRef.current.getEditor();

      const handleSelectionChange = (range) => {
        if (range) {
          const format = quill.getFormat(range.index);
          const letterSpacing = format.letterSpacing || `0px`;
          const fontSize = format.size || `13px`;
          const color = format.color || `black`;
          const lineHeight = format.lineHeight || `unset`;

          setLineHeightValue(lineHeight.split(`px`)[0]);
          setLetterSpacingValue(letterSpacing.split(`px`)[0]);
          setFontSizeValue(fontSize.split(`px`)[0]);
          setColorValue(color);
        }
      };

      quill.on(`selection-change`, handleSelectionChange);

      return () => {
        quill.off(`selection-change`, handleSelectionChange);
      };
    }
    return null;
  }, [isRendered]);

  useEffect(() => {
    const styleElement = document.createElement(`style`);
    document.head.appendChild(styleElement);
    updatedFonts.forEach((font) => {
      styleElement.sheet.insertRule(
        `#toolbar .ql-font span[data-label='${font}']::before { font-family: ${font}; }`,
        styleElement.sheet.cssRules.length,
      );
    });
    if (editorRef?.current && !defaultValue.startsWith(`<p`)) {
      const editor = editorRef.current.getEditor();
      if (editor) {
        const delta = editor.getContents();
        const attributes = defaultAttributes || usualAttributes;
        delta.ops.forEach((op) => {
          if (op.insert) {
            op.attributes = {
              ...op.attributes,
              ...attributes,
            };
          }
        });
        editor.setContents(delta);
      }
    }

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [isRendered]);

  return (
    updatedFonts.length > 0 && (
      <Wrapper ref={ref} className="text-editor">
        <CustomToolbarRichText
          isRendered={isRendered}
          setIsRendered={setIsRendered}
          updatedFonts={updatedFonts}
          setColorValue={setColorValue}
          colorValue={colorValue}
          fontSizeValue={fontSizeValue}
          setFontSizeValue={setFontSizeValue}
          letterSpacingValue={letterSpacingValue}
          setLetterSpacingValue={setLetterSpacingValue}
          lineHeightValue={lineHeightValue}
          setLineHeightValue={setLineHeightValue}
          editorRef={editorRef}
        />
        {isRendered && (
          <ReactQuill
            value={decodeURIComponent(defaultValue)}
            ref={editorRef}
            onChange={(val: string) => onChange(encodeURIComponent(val))}
            modules={modules}
            placeholder="Change me"
          />
        )}
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  background: white;
  margin-bottom: 2rem;
  border-radius: 10px !important;
`;
const usualAttributes = {
  align: `center`,
  font: `Arial`,
  color: `#D32124`,
  'font-size': `33px`,
  'font-weight': `700`,
};
