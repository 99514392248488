import { SelectOptions } from '@/components/small-select';
import { NumberValueOps } from '@/components/query-builder/numeric-value';
import { TextValueOps } from '@/components/query-builder/text-value';

export enum QBType {
  NONE = `NONE`,
  MULTI_VALUE = `MULTI_VALUE`,
  TEXT_VALUE = `TEXT_VALUE`,
  ARRAY_VALUE = `ARRAY_VALUE`,
  NUMERIC_VALUE = `NUMERIC_VALUE`,
}

export interface QBItemProp {
  kind: QBType;
  caption: string;
  group?: string;
  options?: SelectOptions[];
  hasCurrencyCode?: boolean;
  lockCurrency?: boolean;
  explain?: string;
  hideCurrencyCodeCheckbox?: boolean;
  canCreateOption?: boolean;
  hasTimeframe?: boolean;
  envKey?: string;
  disableOrCond?: boolean;
  alsoApplyOnQueryParams?: boolean;
  disableOp?: boolean;
  defaultIsNot?: boolean;
  defaultNumericValue?: number;
  defaultNumericValueOp?: NumberValueOps;
  defaultTextValueOp?: TextValueOps;
}

export enum TimeframeUnit {
  UNKNOWN_TIMEFRAME = `UNKNOWN_TIMEFRAME`,
  HOURS = `HOURS`,
  DAYS = `DAYS`,
  WEEKS = `WEEKS`,
}

export type QBItemProps = Array<QBItemProp>;

export interface FacebookCampaign {
  id: string;
  name: string;
}

export interface FacebookAdSet {
  id: string;
  isExclude?: boolean;
  campaign_id: string;
  name: string;
  campaignName?: string;
}

export interface FacebookAd {
  id: string;
  isExclude?: boolean;
  campaign_id: string;
  adSet_id: string;
  name: string;
  campaignName?: string;
  adsetName?: string;
}

export interface FacebookTargeting {
  campaigns: Array<FacebookCampaign>;
  adsets: Array<FacebookAdSet>;
  ads: Array<FacebookAd>;
  timeframe?: { value: number; unit: TimeframeUnit };
}

export function isEmpty(targeting: FacebookTargeting) {
  return (
    targeting.ads?.filter((x) => !x.isExclude).length === 0 &&
    targeting.adsets?.filter((x) => !x.isExclude).length === 0 &&
    targeting.campaigns.length === 0
  );
}

export interface QBItemSelection {
  qbProps: QBItemProp;
  values: Array<{ op: string; value: string; currency?: string }>;
  timeframe?: { value: number; unit: TimeframeUnit };
  autoApplyCurrency?: boolean;
  includeQueryParams?: boolean;
}

export const example: QBItemProps = [
  {
    kind: QBType.MULTI_VALUE,
    caption: `Collections`,
    options: [
      { key: `cloths`, value: `Cloths` },
      { key: `most pop`, value: `Most Popular` },
      { key: `etc`, value: `Etc` },
    ],
    envKey: `Page`,
  },
  {
    kind: QBType.MULTI_VALUE,
    caption: `Products`,
    options: [
      { key: `necklace`, value: `Necklace` },
      { key: `airpodspro`, value: `Airpods Pro` },
      { key: `devils`, value: `Devil Top Crop Holondez` },
    ],
    envKey: `Page`,
  },
  {
    kind: QBType.MULTI_VALUE,
    caption: `Page`,
    options: [
      { key: `homepage`, value: `Homepage` },
      { key: `cart`, value: `Cart` },
      { key: `checkout`, value: `Checkout` },
    ],
    envKey: `Page`,
  },
  {
    kind: QBType.TEXT_VALUE,
    caption: `UTM Source`,
    envKey: `Page`,
  },
  {
    kind: QBType.TEXT_VALUE,
    caption: `UTM Campaign`,
    envKey: `Page`,
  },
  {
    kind: QBType.TEXT_VALUE,
    caption: `UTM Medium`,
    envKey: `Page`,
  },
  {
    kind: QBType.NUMERIC_VALUE,
    caption: `Price`,
    envKey: `price`,
  },
];
