import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { NumberInput } from '@/features/editor/widgets/custom-widget/inputs/shared/number-input';
import { ColorInputRichText } from '@/features/editor/widgets/custom-widget/inputs/shared/color-input-rich-text';
import { NumberInputImage } from '@/features/editor/widgets/custom-widget/inputs/shared/number-input-image';

const lineHeightSrc =
  require(`../../../../../../assets/line-height.svg`).default;

const ReactQuill =
  typeof window === `object` ? require(`react-quill`) : () => false;

let colorOut = `black`;
let fontSizeOut = `13px`;
let letterSpacingOut = `0px`;
let lineHeightOut = `unset`;
export interface RichTextProps {
  updatedFonts: any[];
  isRendered: boolean;
  setIsRendered: (val: boolean) => void;
  colorValue: string;
  setColorValue: (val: string) => void;
  fontSizeValue: string;
  setFontSizeValue: (val: string) => void;
  letterSpacingValue: string;
  setLetterSpacingValue: (val: string) => void;
  lineHeightValue: string;
  setLineHeightValue: (val: string) => void;
  editorRef: any;
}

export function CustomToolbarRichText({
  isRendered,
  setIsRendered,
  updatedFonts,
  colorValue,
  setColorValue,
  fontSizeValue,
  setFontSizeValue,
  letterSpacingValue,
  setLetterSpacingValue,
  lineHeightValue,
  setLineHeightValue,
  editorRef,
}: RichTextProps) {
  const { Quill } = ReactQuill;
  const Parchment = Quill.import(`parchment`);

  const [showPicker, setShowPicker] = useState(false);

  const buttonColorAction = useRef(null);
  const buttonFontSizeAction = useRef(null);
  const buttonLetterSpacingAction = useRef(null);
  const buttonLineHeightAction = useRef(null);
  const debounceTimeout = useRef(null);

  useEffect(() => {
    const registerStyle = (name, attribute, config) => {
      const style = new Parchment.Attributor.Style(name, attribute, config);
      Quill.register(style, true);
    };

    // Configurations
    const blockScope = { scope: Parchment.Scope.BLOCK, whitelist: null };
    const inlineScope = { scope: Parchment.Scope.INLINE, whitelist: null };

    // Register styles
    registerStyle(`transform`, `text-transform`, {
      ...blockScope,
      whitelist: [`normal`, `uppercase`, `lowercase`],
    });
    registerStyle(`fontWeight`, `font-weight`, inlineScope);
    registerStyle(`letterSpacing`, `letter-spacing`, inlineScope);
    registerStyle(`lineHeight`, `line-height`, blockScope);

    // Register existing Quill styles
    [`color`, `align`, `direction`, `size`, `font`].forEach((styleName) => {
      const style = Quill.import(`attributors/style/${styleName}`);
      if (styleName === `size` || styleName === `font`) style.whitelist = null;
      if (styleName === `font`) style.scope = blockScope.scope;
      Quill.register(style, true);
    });
  }, [isRendered]);

  const onChangeFontSize = (value) => {
    onValueChange(
      setFontSizeValue,
      value,
      buttonFontSizeAction,
      debounceTimeout,
      `fontSize`,
    );
  };

  const onChangeLetterSpacing = (value) => {
    onValueChange(
      setLetterSpacingValue,
      value,
      buttonLetterSpacingAction,
      debounceTimeout,
      `letterSpacing`,
    );
  };

  const onChangeLineHeight = (value) => {
    const newValue =
      value.includes(`NaN`) && isRendered
        ? `${Math.round(parseInt(fontSizeValue, 10) * 1.4).toString()}px`
        : value;

    onValueChange(
      setLineHeightValue,
      newValue,
      buttonLineHeightAction,
      debounceTimeout,
      `lineHeight`,
    );
  };

  useEffect(() => {
    if (showPicker) {
      if (editorRef.current) {
        editorRef.current.editor.root.style.pointerEvents = `none`;
      }
    } else if (editorRef.current) {
      editorRef.current.editor.root.style.pointerEvents = `auto`;
    }
    if (buttonColorAction.current) {
      colorOut = colorValue;
      if (!showPicker) buttonColorAction.current.click();
    }
  }, [showPicker]);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <Wrapper id="toolbar">
      <InputWrapper width={1 / 2}>
        <FontFamilySelect className="ql-font" aria-label="font">
          {updatedFonts.map((option) => (
            <option style={{ fontFamily: option }} key={option} value={option}>
              {option}
            </option>
          ))}
        </FontFamilySelect>
      </InputWrapper>
      <InputWrapper width={1 / 6}>
        <FontSizeInputAction
          ref={buttonFontSizeAction}
          className="ql-fontSizeAction"
          aria-label="FontSizeAction"
        />
        <NumberInput
          min={1}
          aria-label="FontSize"
          max={128}
          suffix="px"
          onChange={onChangeFontSize}
          defaultValue={fontSizeValue}
        />
      </InputWrapper>
      <InputWrapper width={1 / 6}>
        <LetterSpacingInputAction
          ref={buttonLetterSpacingAction}
          className="ql-letterSpacingAction"
          aria-label="LetterSpacingAction"
        />
        <NumberInput
          min={-100}
          aria-label="LetterSpacing"
          max={100}
          step={0.1}
          suffix="px"
          onChange={onChangeLetterSpacing}
          defaultValue={letterSpacingValue}
        />
      </InputWrapper>
      <InputWrapper width={1 / 6}>
        <ColorInputAction
          ref={buttonColorAction}
          className="ql-colorAction"
          aria-label="ColorAction"
        />
        <ColorInputRichText
          showPicker={showPicker}
          setShowPicker={setShowPicker}
          onChange={setColorValue}
          value={colorValue}
        />
      </InputWrapper>
      <InputWrapper width={1 / 3}>
        <FontWeightSelect
          className="ql-fontWeight"
          aria-label="fontWeight"
          defaultValue="400"
        >
          {fontWeightOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </FontWeightSelect>
      </InputWrapper>
      <InputWrapper width={1 / 3}>
        <LineHeightWrapper>
          <LineHeightInputAction
            ref={buttonLineHeightAction}
            className="ql-lineHeightAction"
            aria-label="LineHeightAction"
          />
          <NumberInputImage
            min={1}
            aria-label="LineHeight"
            max={128}
            suffix="px"
            Image={LineHeightIcon}
            onChange={onChangeLineHeight}
            defaultValue={lineHeightValue}
          />
        </LineHeightWrapper>
      </InputWrapper>
      <InputWrapper width={1 / 6}>
        <TextTransformSelect
          className="ql-transform"
          aria-label="transform"
          defaultValue="normal"
        >
          {transformOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextTransformSelect>
      </InputWrapper>
      <InputWrapper width={1 / 6}>
        <LinkButton
          className="ql-link"
          type="button"
          aria-label="Insert Link"
        />
      </InputWrapper>
      <ButtonsWrapper>
        <ItalicButton className="ql-italic" type="button" aria-label="Italic" />
        <UnderlineButton
          className="ql-underline"
          type="button"
          aria-label="Underline"
        />
        <StrikeButton className="ql-strike" type="button" aria-label="Strike" />

        <ListButton
          className="ql-list"
          type="button"
          value="ordered"
          aria-label="Ordered list"
        />
        <ListButton
          className="ql-list"
          type="button"
          value="bullet"
          aria-label="Bullet list"
        />

        <DirectionButton
          className="ql-direction"
          type="button"
          value="rtl"
          aria-label="Right to Left"
        />
        <AlignSelect defaultValue="" className="ql-align" aria-label="Align">
          {alignOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </AlignSelect>

        {/* <CleanButton className="ql-clean" type="button" aria-label="Clean" /> */}
      </ButtonsWrapper>
      <UndoRedoWrapper>
        <UndoButton className="ql-undo">
          <CustomUndo />
        </UndoButton>
        <RedoButton className="ql-redo">
          <CustomRedo />
        </RedoButton>
      </UndoRedoWrapper>
    </Wrapper>
  );
}

function undoChange() {
  this.quill?.history?.undo();
}

function redoChange() {
  this.quill?.history?.redo();
}
function handleColor() {
  this.quill?.format(`color`, colorOut);
}
function handleFontSize() {
  this.quill?.format(`size`, fontSizeOut);
}
function handleLetterSpacing() {
  this.quill?.format(`letterSpacing`, letterSpacingOut);
}

function handleLineHeight() {
  this.quill?.format(`lineHeight`, lineHeightOut);
}
function onValueChange(
  setValue,
  value,
  buttonRef,
  debounceTimeoutRef,
  valueOut,
) {
  setValue(value);

  if (buttonRef.current) {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      if (valueOut === `fontSize`) fontSizeOut = value;
      if (valueOut === `letterSpacing`) letterSpacingOut = value;
      if (valueOut === `lineHeight`) lineHeightOut = value;
      if (buttonRef?.current) buttonRef.current.click();
    }, 1000);
  }
}

export const modules = {
  toolbar: {
    container: `#toolbar`,
    handlers: {
      undo: undoChange,
      redo: redoChange,
      colorAction: handleColor,
      fontSizeAction: handleFontSize,
      letterSpacingAction: handleLetterSpacing,
      lineHeightAction: handleLineHeight,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
  },
};

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

export const fontFamilyOptions = [
  `Helvetica`,
  `Roboto`,
  `Open Sans`,
  `Fredoka`,
  `Smooch Sans`,
  `Rubik`,
  `Lato`,
  `Poppins`,
  `Oswald`,
  `League Spartan`,
  `Noto Sans`,
  `Raleway`,
  `Merriweather`,
  `Playfair Display`,
  `Inter`,
  `Jetbrains Mono`,
];

const transformOptions = [
  { value: `normal`, label: `Aa` },
  { value: `uppercase`, label: `AA` },
  { value: `lowercase`, label: `aa` },
];

const alignOptions = [
  { value: ``, label: `Left` },
  { value: `center`, label: `Center` },
  { value: `right`, label: `Right` },
];

const fontWeightOptions = [
  { label: `Thin`, value: `100` },
  { label: `Ultra Light`, value: `200` },
  { label: `Light`, value: `300` },
  { label: `Normal`, value: `` },
  { label: `Medium`, value: `500` },
  { label: `Semi Bold`, value: `600` },
  { label: `Bold`, value: `700` },
  { label: `Ultra Bold`, value: `800` },
  { label: `Heavy`, value: `900` },
];

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  border: none !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  padding: 12px !important;
  && input {
    scale: 90%;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
const UndoRedoWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;
const TextTransformSelect = styled.select`
  margin-right: 10px;
  width: 65px;

  .ql-picker-item:before {
    content: attr(data-label);
  }

  .ql-picker-label:before {
    content: attr(data-label);
  }
`;
const FontWeightSelect = styled.select`
  margin-right: 10px;
  width: 100px;

  .ql-picker-item:before {
    content: attr(data-label);
  }

  .ql-picker-label:before {
    content: attr(data-label);
  }
`;

const ColorInputAction = styled.button`
  display: none !important;
`;
const FontSizeInputAction = styled.button`
  display: none !important;
`;
const LetterSpacingInputAction = styled.button`
  display: none !important;
`;
const LineHeightInputAction = styled.button`
  display: none !important;
`;

const ItalicButton = styled.button``;

const UnderlineButton = styled.button``;

const StrikeButton = styled.button``;

const ListButton = styled.button``;

const DirectionButton = styled.button``;

const AlignSelect = styled.select``;

const LinkButton = styled.button`
  width: 100% !important;
  padding: 0 17px !important;
`;

const UndoButton = styled.button``;

const RedoButton = styled.button``;

// const CleanButton = styled.button``;

const FontFamilySelect = styled.select``;

const InputWrapper = styled.div`
  width: ${(props: { width: number }) =>
    props.width ? `calc(100% * ${props.width} - 1rem)` : `auto`};
  border-radius: 10px;
  border: 1px #ddd solid;
`;

const LineHeightWrapper = styled.div``;

const LineHeightIcon = () => (
  <img
    src={lineHeightSrc}
    alt="line height"
    height={16}
    placeholder="none"
    loading="eager"
  />
);
