import { useContext, useEffect, useState } from 'react';
import { QBItemSelection } from '@/components/query-builder/models';
import { maybe } from '@/features/details/utils';
import { RuleState } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { SelectOptions } from '@/components/small-select';
import { EditorContext } from '@/features/editor/context/editor-context';

export function hasVariantSelectionSelection(items: Array<QBItemSelection>) {
  return [`item`].includes(maybe(() => items[0].qbProps.envKey));
}

export function getRuleProductHandle(rule: RuleState) {
  return maybe(() => rule.ruleCond.items[0].values[0].value, ``);
}

export function useItemSelectionOptions(rule: RuleState) {
  const { resources } = useContext(EditorContext);

  const productHandle = getRuleProductHandle(rule);
  const [opts, setOpts] = useState<SelectOptions[]>(defaultOptions);
  useEffect(() => {
    if (hasVariantSelectionSelection(rule.ruleCond.items)) {
      const productOptions = maybe(
        () =>
          resources.products
            .find((p) => p.handle === productHandle)
            .options.flatMap((o) =>
              o.values.filter(
                (v) =>
                  v !== `Default Title` &&
                  resources.mainAttributeOptions.includes(v),
              ),
            ),
        [],
      ).map((v) => ({ key: v, value: v } as SelectOptions));
      setOpts([
        {
          key: `all_variants`,
          value: `All Product Variants`,
        },
        ...productOptions,
      ]);
    } else {
      setOpts(defaultOptions);
    }
  }, [rule.ruleCond.items, rule.ruleCond.itemSelection]);
  return opts;
}

const defaultOptions = [
  {
    key: `all_items`,
    value: `All Items in the cart`,
  },
  {
    key: `some_items`,
    value: `Some Items in the cart`,
  },
] as Array<SelectOptions>;

export function selectKey(rule: RuleState, opts: SelectOptions[]) {
  return maybe(
    () => `k${rule.ruleCond.itemSelection}${opts.map((o) => o.value).join()}`,
    ``,
  );
}
