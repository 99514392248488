import { CachePolicies, useFetch } from 'use-http';
import { routes } from '@/webapi/routes';
import {
  generateUUID,
  handleJsonResult,
  mimeTypeToExtension,
} from '@/webapi/common';

export function useMediaApi() {
  const { post, error, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: true,
    },
  }));

  const listStockPhotos = async (
    page: number,
    size: number,
    options: {
      searchText: string;
    },
  ): Promise<StockImageList> => {
    const response = post(routes.listStockImages(), {
      query: options.searchText,
      page,
      size,
    });
    const resp = await handleJsonResult(response, error);
    resp.total = Math.min(resp.total, 100);
    return Promise.resolve(resp);
  };

  const listGalleryPhotos = async (
    page: number,
    size: number,
  ): Promise<GalleryPhotoList> =>
    post(routes.listGalleryImages(), {
      page,
      size,
    });

  const softDeleteImage = async (
    checksum: string,
    id: string,
  ): Promise<{ value: string }> =>
    post(routes.softDelImage(), {
      id,
      checksum,
    });

  const uploadImage = async (thumbnail: Blob, mobile: Blob, desktop: Blob) => {
    const data = new FormData();
    const filename = `${generateUUID()}.${
      mimeTypeToExtension(thumbnail.type) || `jpeg`
    }`;
    data.append(`thumbFile`, thumbnail, `thumb_${filename}`);
    data.append(`mobileFile`, mobile, `mobile_${filename}`);
    data.append(`desktopFile`, desktop, `desktop_${filename}`);

    return post(routes.uploadMediaToGallery(), data);
  };

  return {
    listStockPhotos,
    listGalleryPhotos,
    uploadImage,
    softDeleteImage,
    loading,
  };
}

export interface StockImageList {
  images: StockImage[];
  total: number;
}

export interface StockImage {
  id: string;
  color: string;
  likes: number;
  description: string;
  photographerName: string;
  photographerLink: string;
  thumbnailLink: string;
  regularLink: string;
  rawLink: string;
  downloadLink: string;
}

export interface GalleryPhotoList {
  photos: GalleryPhoto[];
  total: number;
}

export interface GalleryPhoto {
  id: string;
  removed?: boolean;
  checksum?: string;
  thumbnailLink: string;
  mobileLink: string;
  desktopLink: string;
  rawLink: string;
}
