import React, { useContext, useMemo, useRef } from 'react';
import { PinturaEditor } from 'react-pintura';
import 'pintura/pintura.css';
import {
  createDefaultImageReader,
  createDefaultImageScrambler,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
  PinturaWriteState,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_filter,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_finetune_locale_en_gb,
  plugin_frame,
  plugin_frame_defaults,
  plugin_frame_locale_en_gb,
  plugin_redact,
  plugin_redact_locale_en_gb,
  plugin_sticker,
  plugin_sticker_locale_en_gb,
  setPlugins,
} from 'pintura';
import { ImageEditorContext } from '@/features/editor/widgets/custom-widget/inputs/background/image/shared/context';
import styled from 'styled-components';
import { useReportError } from '@/utils/errors';

setPlugins(
  plugin_crop,
  plugin_finetune,
  plugin_filter,
  plugin_annotate,
  plugin_sticker,
  plugin_redact,
  plugin_frame,
);

const editorDefaults = {
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  imageScrambler: createDefaultImageScrambler(),
  shapePreprocessor: createDefaultShapePreprocessor(),
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...plugin_sticker,
  ...plugin_redact,
  ...plugin_frame_defaults,
  ...markup_editor_defaults,
  locale: {
    ...locale_en_gb,
    labelButtonExport: `Preview on site`,
    ...plugin_crop_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...plugin_annotate_locale_en_gb,
    ...plugin_sticker_locale_en_gb,
    ...plugin_redact_locale_en_gb,
    ...plugin_frame_locale_en_gb,
    ...markup_editor_locale_en_gb,
  },
};

export function ImageEditor({
  onUpdatePreview,
}: {
  onUpdatePreview?: (blob: string) => void;
}) {
  const { image, aspectRatio } = useContext(ImageEditorContext);
  const componentRef = useRef(null);
  const prevImageBlob = localStorage.getItem('vsly_img_blob') || '';

  const onReady = () => {
    if (componentRef) {
      const { editor } = componentRef.current;
      editor.processImage();
    }
  };
  const { reportErr } = useReportError();

  const report = (err: PinturaWriteState, type: string) => {
    reportErr({
      err,
      errMsg: (err?.error as Error)?.message,
      errStack: (err?.error as Error)?.stack,
      errName: (err?.error as Error)?.name,
      type: type,
      group: 'pintura',
      image,
      prevImageBlob,
      aspectRatio,
    });
  };
  return (
    <EditorWrapper>
      {/*@ts-ignore*/}
      <PinturaEditor
        ref={componentRef}
        {...editorDefaults}
        onLoad={(_) => {
          URL.revokeObjectURL(prevImageBlob);
        }}
        layoutDirectionPreference="vertical"
        layoutHorizontalUtilsPreference="bottom"
        cropEnableInfoIndicator
        imageCropAspectRatio={aspectRatio}
        cropEnableImageSelection
        src={image}
        onReady={onReady}
        onLoaderror={(err) => {
          report(err, 'onLoaderror');
        }}
        onProcessabort={(err) => {
          report(err, 'onProcessabort');
        }}
        onProcesserror={(err) => {
          report(err, 'onProcesserror');
        }}
        onProcess={({ dest }: any) => {
          const blob = URL.createObjectURL(dest);
          onUpdatePreview && onUpdatePreview(blob);
          URL.revokeObjectURL(prevImageBlob);
          localStorage.setItem('vsly_img_blob', blob);
        }}
      />
    </EditorWrapper>
  );
}

const EditorWrapper = styled.div`
  height: 100%;

  .PinturaRoot {
    background-color: white;
  }
`;
