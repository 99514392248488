import produce from 'immer';
import { WritableDraft } from 'immer/dist/types/types-external';
import md5 from 'blueimp-md5';
import {
  CatalogWidgetProps,
  Component,
  CustomizationSpec,
  LoadingEnv,
  RecommendationType,
  Spec,
} from '@/webapi/use-widget-catalog-api';
import {
  ORIGINAL_EXP_ID,
  ORIGINAL_EXP_NAME,
} from '@/features/editor/shared/external_experience_widget';

// ###########################
// Until we move this file to a shared repo
// Changes made here should be replicated to svc-apps-catalog/internal/apps/src/shared/utils/env.ts
// ###########################

export function schemaToEnv(
  schema: CatalogWidgetProps,
  sectionId?: string,
  expName?: string,
  expId?: string,
): Record<string, any> {
  const env = {};
  if (sectionId) env[`sectionId`] = `#${sectionId.replaceAll(`#`, ``)}`;
  if (expName) env[ORIGINAL_EXP_NAME] = expName;
  if (expId) env[ORIGINAL_EXP_ID] = expId;

  schema?.customizations?.forEach(
    ({ key, isDisabled, canBeDisabled, components }) => {
      env[key] = parseComps(components);
      if (canBeDisabled) {
        env[key].isDisabled = isDisabled || false;
      }
    },
  );
  if (schema?.settings?.general) {
    env[schema.settings.general.key] = parseGeneralSettings(
      schema.settings.general.specs,
    );
  }
  if (schema?.settings?.loading?.loadingEnv) {
    env[`loading`] = cleanupRecommendationRequest(schema);
  }

  if (schema?.settings?.uiByGroup) {
    env[`uiByGroup`] = schema?.settings?.uiByGroup;
  }

  return env;
}

function parseComps(components: Component[]): any {
  const comp = {};
  components.forEach(({ key, specs }) => {
    comp[key] = parseSpecs(specs);
  });
  return comp;
}

function parseSpecs(specs: CustomizationSpec[]): any {
  const spec = {};
  specs.forEach(({ key, values }) => {
    spec[key] = values;
  });
  return spec;
}

export function parseGeneralSettings(specs: Spec[]): any {
  const settings = {};
  specs.forEach(({ key, value }) => {
    settings[key] = value;
  });
  return settings;
}

export function mergeDevicesOnGeneral(general: any): any {
  const settings = {};
  Object.entries(general).forEach(([key, value]) => {
    if (value?.[`desktop`]) {
      settings[key] = value?.[`desktop`];
    } else {
      settings[key] = value;
    }
  });
  return settings;
}

let count = 0;
const shortHash = {};
function hashFilter(s) {
  const hash = md5(JSON.stringify(s.filter));
  if (typeof shortHash[hash] === `undefined`) {
    count += 1;
    shortHash[hash] = count;
    return count;
  }
  return shortHash[hash];
}

function handleLayeredRuling(draft: WritableDraft<LoadingEnv>) {
  delete draft.recommendationOptions.conditionId;
  draft.recommendationOptions.layeredRuling =
    draft.recommendationOptions.layeredRuling.filter(
      (r) => !!r.strategy || r.strategyPerSlot?.slots?.length > 0,
    );
  draft.recommendationOptions.layeredRuling.forEach((r) => {
    delete r.isOpen;
    if (r.ruleCond?.itemSelection === ``) {
      delete r.ruleCond.itemSelection;
    }
    delete r.hash;
    r.ruleCond?.items?.forEach?.(cleanCondition());
    if (isManual(r?.strategy)) {
      delete r.strategy;
      delete r.strategyCond;
    } else {
      delete r.strategyPerSlot;
      r.strategyCond?.forEach?.(cleanCondition());
    }

    r.strategyPerSlot?.slots?.forEach?.((s) => {
      delete s.hash;
      delete s.num;
      if (isManual(s.strategy)) {
        delete s.strategy;
        cleanManualProduct(s);
      } else {
        delete s.manualProduct;
      }
      if (typeof draft.recommendationOptions.conditions === `undefined`) {
        draft.recommendationOptions.conditions = {};
      }
      s.filter?.forEach?.(cleanCondition());
      if (s?.filter?.length > 0) {
        const filterHash = hashFilter(s);
        if (hasCondition(draft, filterHash)) {
          draft.recommendationOptions.conditions[filterHash] = s.filter;
        }
        s.h = filterHash;
        delete s.filter;
      }
    });

    if (typeof r.strategyPerSlot?.slots !== `undefined`) {
      r.strategyPerSlot.slots = r.strategyPerSlot.slots.filter(
        (s) => !!s.strategy || !!s.manualProduct,
      );
    }
  });
}

function handleStrategyPerSlot(draft: any) {
  delete draft.recommendationOptions.conditionId;
  draft.recommendationOptions.strategyPerSlot.forEach((s) => {
    if (s.manualProduct) {
      cleanManualProduct(s);
    }
    delete s.hash;
    delete s.num;
    s?.filter?.forEach?.(cleanCondition());
  });
  draft.recommendationOptions.strategyPerSlot =
    draft.recommendationOptions.strategyPerSlot.filter(
      (s) => !!s?.strategy || !!s?.manualProduct,
    );
}

export function cleanupLoadingEnvOptions(draft: LoadingEnv): LoadingEnv {
  if (draft?.recommendationOptions?.condition) {
    draft.recommendationOptions.condition.forEach(cleanConditionOptions());
  }
  if (draft?.recommendationOptions?.advancedRuling) {
    draft.recommendationOptions.advancedRuling.forEach((r) => {
      r.condition?.forEach(cleanConditionOptions());
    });
  }
  if (draft?.recommendationOptions?.strategyPerSlot) {
    draft.recommendationOptions.strategyPerSlot.forEach((s) => {
      s?.filter?.forEach?.(cleanConditionOptions());
    });
  }
  if (draft?.recommendationOptions?.layeredRuling) {
    draft.recommendationOptions.layeredRuling.forEach((r) => {
      r.ruleCond?.items?.forEach?.(cleanConditionOptions());
      r.strategyCond?.forEach?.(cleanConditionOptions());

      r.strategyPerSlot?.slots?.forEach?.((s) => {
        s.filter?.forEach?.(cleanConditionOptions());
      });
    });
  }
  return draft;
}

function cleanConditionOptions() {
  return (c) => {
    if (!c?.qbProps) return;
    delete c.qbProps.options;
  };
}

export function cleanupRecommendationRequest(schema: CatalogWidgetProps) {
  return produce(schema.settings.loading.loadingEnv, (draft) => {
    if (draft.recommendationOptions?.condition) {
      draft.recommendationOptions.condition.forEach(cleanCondition());
    }
    if (not(draft, RecommendationType.ADVANCED_RULING)) {
      delete draft.recommendationOptions.advancedRuling;
      delete draft.recommendationOptions.layeredRuling;
      delete draft.recommendationOptions.conditions;
    }
    if (not(draft, RecommendationType.MANUAL)) {
      delete draft.recommendationOptions.manualProducts;
      delete draft.recommendationOptions.strategyPerSlot;
    }

    if (draft.recommendationOptions?.advancedRuling) {
      draft.recommendationOptions.advancedRuling.forEach((r) => {
        r.condition.forEach(cleanCondition());
      });
    }
    if (draft.recommendationOptions?.strategyPerSlot) {
      handleStrategyPerSlot(draft);
    }
    if (draft.recommendationOptions?.layeredRuling) {
      handleLayeredRuling(draft);
    }
  });
}

function not(draft: any, recType: RecommendationType) {
  return draft?.recommendationOptions.type !== recType;
}

function cleanCondition() {
  return (c) => {
    if (!c?.qbProps) return;
    delete c.qbProps.options;
    delete c.qbProps.hideCurrencyCodeCheckbox;
    delete c.qbProps.explain;
    delete c.qbProps.caption;
    delete c.qbProps.kind;
    delete c.qbProps.defaultNumericValue;
    delete c.qbProps.disableOrCond;
    delete c.qbProps.disableOp;
    delete c.qbProps.defaultIsNot;
    delete c.qbProps.defaultNumericValue;
    delete c.qbProps.defaultNumericValueOp;
    delete c.qbProps.defaultTextValueOp;
    delete c.qbProps.group;
    delete c.qbProps.hasCurrencyCode;
    delete c.qbProps.canCreateOption;
    delete c.qbProps.hasTimeframe;
  };
}

function isManual(strategy) {
  return strategy === RecommendationType.MANUAL;
}

function cleanManualProduct(s) {
  if (!s?.manualProduct) return;
  delete s.manualProduct.optionNames;
  delete s.manualProduct.handle;
  delete s.manualProduct.image;
  delete s.manualProduct.images;
  delete s.manualProduct.compareAtPrice;
  delete s.manualProduct.missingVariantImageMapping;
  delete s.manualProduct.price;
  delete s.manualProduct.title;
  delete s.manualProduct.compareAtPrice;
}

function hasCondition(draft: WritableDraft<LoadingEnv>, filterHash) {
  return (
    typeof draft.recommendationOptions.conditions[filterHash] === `undefined`
  );
}
