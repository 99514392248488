import * as React from 'react';
import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { EditorContext } from '@/features/editor/context/editor-context';
import { centered, useSharedElement } from '@/components/use-shared-element';
import { UNNAMED_EXPERIENCE } from '@/webapi/use-experience-api';
import { SharedElementOverlay } from '@/components/shared-element-overlay';
import { InputModal } from '@/components/input-modal';
import { AccountContext } from '@/features/account-context';
import { StoreStatsKind } from '@/webapi/use-store-stats';

export function EditorRenameButton() {
  const { isStoreGoalReached } = useContext(AccountContext);
  const {
    experienceState: { currentExperience, setExperienceName },
  } = useContext(EditorContext);

  const {
    props: renameProps,
    show: showRenameModal,
    hide: hideRenameModal,
    fromRef: renameRef,
  } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `transparent`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    undefined,
    () => centered(23, 42),
  );

  const onRename = (name: string) => {
    setExperienceName(name);
    hideRenameModal();
  };

  const isUnnamed = useMemo(
    () =>
      currentExperience.name === UNNAMED_EXPERIENCE ||
      currentExperience.name === ``,
    [currentExperience.name],
  );

  const experienceTitle = useMemo(() => {
    if (isUnnamed) {
      if (!isStoreGoalReached(StoreStatsKind.HAS_SAVED_ONE_EXPERIENCE)) {
        return `Welcome, Let's create your first experience`;
      }
      return `Create a New Experience`;
    }
    return currentExperience.name;
  }, [currentExperience.name, isUnnamed]);

  return (
    <>
      <SharedElementOverlay {...renameProps}>
        <InputModal
          title="Rename Experience?"
          description="Name your experience"
          yesCaption="Rename"
          noCaption="Discard"
          placeholder={isUnnamed ? currentExperience?.name : undefined}
          initialValue={isUnnamed ? undefined : currentExperience?.name}
          onConfirm={onRename}
          onDiscard={hideRenameModal}
        />
      </SharedElementOverlay>
      <Title ref={renameRef} onClick={showRenameModal}>
        {experienceTitle}
      </Title>
    </>
  );
}

const Title = styled.span`
  && {
    cursor: context-menu;

    :hover {
      opacity: 0.7;
    }
  }
`;
