import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { EditorRenameButton } from '@/features/editor/widgets/shared/layout/editor-rename-button';
import { PublishModal } from '@/features/editor/use-publish-modal';
import {
  ScheduleModal,
  useScheduleModal,
} from '@/features/editor/use-schedule-modal';
import { InspectorTargetingView } from '@/features/editor/widgets/targeting/targeting-view';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';
import { BigButton } from '@/components/big-button';
import { GlossyWrapper } from '@/components/glossy-wrapper';
import { useIsPreviewingDesktop } from '@/features/editor/shared/use-device-type';
import { EnablePostPurchaseModal } from '@/features/editor/widgets/post-purchase/enable-post-purchase';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DescriptionEditButton } from '@/features/editor/widgets/shared/layout/descr-rename-button';
import { FlexCol } from '@/components/flex';

export function PostPurchaseReviewStep() {
  const {
    experienceState: { currentExperience },
  } = useContext(EditorContext);

  const {
    gotoCustomize,
    hasChanges,
    onSave,
    onPublish,
    publishButtonRef,
    publishModalProps,
  } = useContext(PostPurchaseContext);

  const isDesk = useIsPreviewingDesktop();

  const scheduleModalProps = useScheduleModal(
    publishButtonRef,
    currentExperience,
  );

  return (
    <InspectorWidgetLayout
      backCaption="< Back to customization"
      onBackClicked={gotoCustomize}
      title={
        <FlexCol>
          <span>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Review "<EditorRenameButton />" before publish
          </span>
          <DescriptionEditButton />
        </FlexCol>
      }
      progress={0}
      showCloseButton
      footerJustify="flex-start"
      footer={
        <FooterSection>
          <BigButton
            disabled={!hasChanges}
            border="2px solid #C8CACB"
            background="white"
            color="#97A0A8"
            noShadow
            noTransform
            size={isDesk ? `medium-thin` : `medium`}
            fillWidth
            onClick={onSave}
          >
            Save
          </BigButton>
          <BigButton
            ref={publishButtonRef}
            color="white"
            background="black"
            noTransform
            fillWidth
            size={isDesk ? `medium-thin` : `medium`}
            onClick={onPublish}
          >
            Publish
          </BigButton>
        </FooterSection>
      }
    >
      <>
        <EnablePostPurchaseModal fromRef={publishButtonRef} />
        <PublishModal {...publishModalProps} />
        <ScheduleModal {...scheduleModalProps} />
        <InspectorTargetingView
          hideGoogleAnalytics
          hideABGoals
          disableDevicesPicker
          disablePlacementPicker
          disableTriggerPicker
          disableThemePickerPicker
          onChangesClicked={gotoCustomize}
        />
      </>
    </InspectorWidgetLayout>
  );
}

const FooterSection = styled(GlossyWrapper)`
  && {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 2rem;
    margin-left: -1rem;
    z-index: 40;
    margin-bottom: 2rem;
    width: 65%;
  }
`;
