import React, { useContext } from 'react';
import styled from 'styled-components';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { PreviewNavigation } from '@/features/editor/preview-navigation';
import { ExperienceSpinner } from '@/components/experience-spinner';

export const DEVICE_WRAPPER_ID = `vsly-device-wrapper`;

export function DevicePreview() {
  const { previewLoading, devicePreview, deviceNavigation, deviceRef } =
    useContext(EditorContext);
  const { encodedUrl } = deviceNavigation;
  const {
    iframeRef,
    editorState: { device },
  } = devicePreview;

  const onMouseLeave = () => {
    devicePreview.looseFocus();
  };

  return (
    <Wrapper>
      {previewLoading && <EditorSpinner size={4} thickness={0.5} />}
      <PreviewNavigation />
      <DeviceWrapper
        id={DEVICE_WRAPPER_ID}
        device={device}
        ref={deviceRef}
        onMouseLeave={onMouseLeave}
      >
        <StyledIframe
          previewLoading={previewLoading}
          ref={iframeRef}
          src={encodedUrl}
          sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
        />
      </DeviceWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  && {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 1rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const EditorSpinner = styled(ExperienceSpinner)`
  && {
    position: absolute;
    align-self: center;
    justify-self: center;
  }
`;

const DeviceWrapper = styled.div`
  display: block;
  position: relative;
  border: solid 0.2rem #dedede;
  background: rgba(244, 244, 244, 0.7);
  border-radius: 2.8rem;
  height: 100%;
  width: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `100%` : `40rem`};

  box-shadow: 0 18px 26px 0 rgba(157, 255, 221, 0.18),
    0 4px 20px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;

  iframe {
    border-radius: 2.5rem;
    height: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `133%` : `100%`};
    width: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `133.4%` : `100%`};

    transform: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `scale(0.75)` : `scale(1)`};
    transform-origin: 0 0;
  }
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: none !important;
  transition: opacity 0.4s ease-out;

  opacity: ${(props: { previewLoading: boolean }) =>
    props.previewLoading ? 0.2 : 1};

  filter: ${(props: { previewLoading: boolean }) =>
    props.previewLoading ? `blur(4px)` : `none`};

  pointer-events: ${(props: { previewLoading: boolean }) =>
    props.previewLoading ? `none` : `auto`};
`;
