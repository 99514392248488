import { useEffect } from 'react';
import produce from 'immer';
import { useComplexState } from '@/utils/use-complex-state';
import { CheckoutWidgetChange } from '@/pkg/sdk';
import { hashObject } from '@/components/hooks/use-cached-auto-save';
import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';
import { RecsProduct } from '@/features/editor/widgets/post-purchase/props';
import { recommendProducts } from '@/features/editor/widgets/post-purchase/use-offers';
import { ExperienceStateHook } from '@/features/editor/context/use-experience-state';
import { ExtensionType } from '@/features/editor/context/use-device-preview';

export function useCheckoutRecommend(
  currentExtension: ExtensionType,
  experienceState: ExperienceStateHook,
) {
  const {
    currentExperience,
    findAllCheckoutExtensibilityChanges,
    upsertEditorChange,
  } = experienceState;

  const [cache, setCache] = useComplexState({});

  useEffect(() => {
    if (currentExtension === `shopify-checkout-extensibility`) {
      const checkoutChanges = findAllCheckoutExtensibilityChanges();
      checkoutChanges?.forEach((ch) => {
        const props = (ch.block.value as CheckoutWidgetChange)?.props;
        const loadingEnv = props?.loadingEnv;
        if (loadingEnv) {
          const key = hashObject(loadingEnv);
          const cachedValue = cache[key];
          if (cachedValue) {
            upsertEditorChange(produceChangeWithRecs(ch, cachedValue));
          } else {
            recommendProducts(loadingEnv).then((recs) => {
              setCache((draft) => {
                draft[key] = recs;
              });
              upsertEditorChange(produceChangeWithRecs(ch, recs));
            });
          }
        }
      });
    }
  }, [currentExperience, currentExtension]);

  const produceChangeWithRecs = (
    change: EditorDeclarativeBlock,
    recs: RecsProduct[],
  ): EditorDeclarativeBlock =>
    produce(change, (draft) => {
      (draft.block.value as CheckoutWidgetChange).props.products = recs;
    });
}
