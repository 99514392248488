import { useContext, useEffect, useState } from 'react';
import { AccountContext } from '@/features/account-context';
import {
  PostPurchaseProps,
  RecsProduct,
} from '@/features/editor/widgets/post-purchase/props';
import { hashObject } from '@/components/hooks/use-cached-auto-save';
import {
  LoadingEnv,
  RecommendationOptions,
  RecommendationType,
} from '@/webapi/use-widget-catalog-api';
import { mutateFn } from '@/utils/use-complex-state';
import { routes } from '@/webapi/routes';
import { cleanupRecommendationRequest } from '@/features/editor/widgets/custom-widget/env';
import { clone } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/shared';

export function useOfferProducts(
  props?: PostPurchaseProps,
  setProps?: (fn: mutateFn<PostPurchaseProps>) => void,
) {
  const {
    account: {
      store: { alias },
    },
  } = useContext(AccountContext);
  const [recs, setRecs] = useState<RecsProduct[]>([]);

  const [loadingEnvHash, setLoadingEnvHash] = useState(hashLoading(props));

  useEffect(() => {
    setLoadingEnvHash(hashLoading(props));
  }, [props]);

  const shouldReplaceWithMostPopular = (strategy: RecommendationType) =>
    [
      RecommendationType.MOST_EXPENSIVE_IN_CART,
      RecommendationType.MOST_CHEAP_IN_CART,
    ].includes(strategy) || strategy === undefined;

  useEffect(() => {
    const promises = props?.offers
      ?.map((i) => ({ strategy: i.loadingStrategy, env: i.loadingEnv }))
      ?.map((i) => ({
        strategy: i.strategy,
        env: {
          recommendationOptions: {
            ...i?.env?.recommendationOptions,
            type: shouldReplaceWithMostPopular(i.strategy)
              ? RecommendationType.MOST_POPULAR
              : i.strategy,
            storeAlias: alias,
          },
        },
      }))
      ?.map((i) => {
        i.env.recommendationOptions = cleanupRecommendationRequest(
          clone({
            settings: {
              loading: {
                loadingEnv: i.env,
              },
            },
          } as any),
        ).recommendationOptions;
        return recommendProducts(i.env);
      });
    Promise.all([...promises]).then((allOffers) =>
      setRecs(
        [
          allOffers?.[0]?.[0],
          allOffers?.[1]?.[1] || allOffers?.[1]?.[0],
        ].filter((i) => i !== undefined),
      ),
    );
  }, [loadingEnvHash]);

  useEffect(() => {
    const newOffers = props?.offers?.map((old, i) => ({
      ...old,
      product: recs[i],
    }));
    setProps((draft) => {
      draft.offers = newOffers;
    });
  }, [recs]);

  return {
    recs,
  };
}

export async function recommendProducts(
  loadingEnv?: LoadingEnv,
): Promise<Array<RecsProduct>> {
  if (!loadingEnv) return Promise.resolve([]);
  try {
    let options = loadingEnv?.recommendationOptions;
    if (!options) {
      return Promise.resolve([]);
    }

    if (options.condition?.length > 0) {
      options = {
        ...options,
        condition: options.condition.map((cond) => ({
          ...cond,
          qbProps: {
            ...cond.qbProps,
            options: [],
          },
        })),
      };
    }

    if (!options.widgetContext) {
      options = {
        ...options,
        widgetContext: {
          sectionId: `#_sectionid`,
          widgetId: `4120b0aef951499c8b1d3bd0fbd75912`,
          widgetVersion: `_29`,
          atcEnabled: true,
        },
      };
    }

    const query = btoa(
      unescape(
        encodeURIComponent(
          JSON.stringify({ ...options } as RecommendationOptions),
        ),
      ),
    );

    if (cache[query]) {
      return cache[query];
    }

    const resp = await fetch(routes.recommendProducts(query));
    const response = await resp.json();
    cache[query] = response!.products;
    return response!.products;
  } catch (ex) {
    console.error(`Failed to load products`, ex);
    return Promise.resolve([]);
  }
}

function hashLoading(props: PostPurchaseProps): string {
  return hashObject(
    props?.offers?.map((i) => ({
      loadingStrategy: i.loadingStrategy,
      loadingEnv: i.loadingEnv,
    })),
  );
}

const cache: Record<string, RecsProduct[]> = {};
