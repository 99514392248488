import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { IoCloseSharp } from 'react-icons/io5';
import { EditorContext } from '@/features/editor/context/editor-context';
import { ConfirmModal } from '@/components/confirm-modal';
import { SharedElementOverlay } from '@/components/shared-element-overlay';
import { useIsPreviewingDesktop } from '@/features/editor/shared/use-device-type';
import Portal from '@/components/portal';

export function CloseEditorButton() {
  const { experienceControls } = useContext(EditorContext);
  const isDesktop = useIsPreviewingDesktop();

  const {
    sharedElement: { props, fromRef, hide },
    onCloseEditorPageClicked,
    closeAndNavigateToDashboard,
  } = experienceControls;

  const onClose = () => {
    onCloseEditorPageClicked();
  };

  const onConfirmClose = () => {
    closeAndNavigateToDashboard();
  };

  return (
    <Wrapper onClick={onClose} isDesktop={isDesktop}>
      <div ref={fromRef} />
      <Portal selector="body">
        <SharedElementOverlay {...props}>
          <ConfirmModal
            neutral
            onDiscard={hide}
            onConfirm={onConfirmClose}
            title="You have unsaved changes"
            description="Are you sure you want to discard the changes?"
            yesCaption="Discard Changes"
            noCaption="Continue Editing"
          />
        </SharedElementOverlay>
      </Portal>
      <IoCloseSharp size={27} />
    </Wrapper>
  );
}

interface WP {
  isDesktop: boolean;
}

const Wrapper = styled.div`
  position: fixed;
  top: ${(p: WP) => (p.isDesktop ? `3rem` : `4rem`)};
  right: ${(p: WP) => (p.isDesktop ? `3rem` : `5rem`)};
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.3rem;

  background-color: #e6edef;
  color: #9daeb2;
  cursor: pointer;
  border-radius: 50%;

  transition: top 0.2s ease-out, right 0.2s ease-out, padding 0.2s ease-out;
  pointer-events: all !important;
  :hover {
    padding: 0.7rem;
    top: ${(p: WP) => (p.isDesktop ? `2.6rem` : `3.6rem`)};
    right: ${(p: WP) => (p.isDesktop ? `2.6rem` : `4.6rem`)};
  }

  :active {
    padding: 0.3rem;
    top: ${(p: WP) => (p.isDesktop ? `3rem` : `4rem`)};
    right: ${(p: WP) => (p.isDesktop ? `3rem` : `5rem`)};
  }
`;
